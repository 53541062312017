import Vue from 'vue'
import VueI18n from 'vue-i18n'
import insane from 'insane'
import { marked } from 'marked'

import Preferences from '@/plugins/preferences'
import EventBus from '@/plugins/eventbus'
import { INSANE_CONFIG, getBrowserLocale, getSupportedLocales } from '@/utils/i18n'

Vue.use(VueI18n)

const supportedLocales = getSupportedLocales()

function getStartingLocale() {
  const userLocale = getBrowserLocale({ countryCodeOnly: true })
  if (Object.keys(supportedLocales).includes(userLocale)) return userLocale
  return 'en'
}

const startingLocale = getStartingLocale()

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: 'en',
  messages: {},
})

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadAsyncLocale(locale) {
  const { availableLocales } = i18n

  if (availableLocales.length > i18n.locale === locale) {
    return setI18nLanguage(locale)
  }

  if (availableLocales.includes(locale)) {
    return setI18nLanguage(locale)
  }

  const messages = await import(
    /* webpackChunkName: "[request].lang" */
    `@/lang/${locale}.json`
  )

  i18n.setLocaleMessage(locale, messages.default)
  return setI18nLanguage(locale)
}

VueI18n.prototype.supportedLocales = Object.keys(supportedLocales)
VueI18n.prototype.loadAsyncLocale = loadAsyncLocale
VueI18n.prototype.mdt = (path, ...args) => insane(
  marked.parseInline(i18n.t(path, ...args), { gfm: false }), // gfm: false to disable autolinking in translations
  INSANE_CONFIG,
)

Vue.prototype.$mdt = i18n.mdt

EventBus.$once('app:created', async vm => {
  const userPreferredLocale = await Preferences.get('lang')
  vm.$store.dispatch('device/loadLocale', userPreferredLocale || startingLocale)
})

export default i18n

// https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n
