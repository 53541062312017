import PullToRefresh from 'pulltorefreshjs'

export default {
  data() {
    return {
      ptr: null,
    }
  },
  beforeDestroy() {
    this.ptrDestroy()
  },
  methods: {
    ptrInit(opts) {
      this.ptrDestroy()

      this.ptr = PullToRefresh.init({
        mainElement: '#app .v-main .v-main__wrap > div',
        onRefresh: () => {
          try {
            this.ptrOnRefresh()
          } catch (error) {
            // throwing would bug out the ptr overlay
            console.error(error)
          }
        },
        instructionsPullToRefresh: this.$t('common.ptr.pull'),
        instructionsReleaseToRefresh: this.$t('common.ptr.release'),
        instructionsRefreshing: this.$t('common.ptr.refreshing'),
        shouldPullToRefresh() {
          // don't activate if not at top of scroll element
          if (opts?.mainElement && this.mainElement.scrollTop) return false
          // if default element, we fall back to window scroll
          if (window.scrollY) return false

          // Don't activate when modals are open
          return !document.documentElement.classList.contains('overflow-y-hidden')
        },
        ...opts,
      })
    },
    ptrDestroy() {
      this.ptr?.destroy()
      this.ptr = null
    },
    ptrOnRefresh() {
      throw new Error('No refresh action defined')
    },
  },
}
