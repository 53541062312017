<template>
  <v-bottom-sheet
    ref="sheet" v-model="open"
    :persistent="persistent"
    :content-class="`bottom-sheet-draggable ${moving ? 'is-moving' : ''}`"
    v-bind="$attrs"
  >
    <template #activator="on">
      <slot name="activator" v-bind="on" />
    </template>

    <v-card ref="content" :color="color" class="rounded-b-0" flat>
      <div ref="handle">
        <slot name="handle">
          <div class="handle text-center py-2">
            <div class="handle-grabber" :class="[handleColor]" :style="{color: handleColor}" />
          </div>
        </slot>
      </div>
      <v-card-text class="pa-0 bottom-sheet-content">
        <slot />
      </v-card-text>

      <v-sheet :color="color" class="overscroll" />
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import Hammer from 'hammerjs'

// NOTE allow free scroll? (dont reset on end)
// NOTE handle hardware back button

export default {
  props: {
    value: Boolean,
    persistent: Boolean,
    threshold: {
      type: Number,
      default: 0.75,
    },
    color: {
      type: String,
      default: 'neutral25',
    },
    handleOnly: {
      type: Boolean,
      default: true,
    },
    handleColor: {
      type: String,
      default: 'neutral600',
    },
  },
  data() {
    return {
      mc: null,
      open: this.value,
      moving: false,
    }
  },
  watch: {
    value: {
      async handler(active) {
        this.open = active

        if (active && !this.mc) {
          await this.$nextTick()
          this.setup()
        }
      },
      immediate: true,
    },
    open(val) {
      this.$emit('input', val)
    },
  },
  beforeDestroy() {
    this.mc?.destroy()
  },
  methods: {
    setup() {
      this.mc?.destroy()

      const { handle } = this.$refs
      this.mc = new Hammer(handle)
      this.mc.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL })

      this.mc.on('pan', this.onPan)
    },
    onPan(e) {
      const { sheet } = this.$refs
      const { dialog } = sheet.$refs
      const height = dialog.offsetHeight

      switch (e.eventType) {
        case Hammer.INPUT_END:
          this.moving = false
          if (!this.persistent) {
            if (e.deltaY / height >= this.threshold) {
              this.onClose(e)
            } else if (e.velocityY > 0.75 && e.deltaY / height > 0.05) {
              this.onClose(e)
            }
          }
          requestAnimationFrame(() => {
            dialog.style.transform = ''
          })
          break
        case Hammer.INPUT_MOVE:
          this.moving = true
          requestAnimationFrame(() => {
            dialog.style.transform = `translateY(${e.deltaY}px)`
          })
          break
        default:
      }
    },
    async onClose(e) {
      const { sheet } = this.$refs

      sheet.onClickOutside(e)

      await this.$nextTick()
      if (this.open) return

      const { dialog } = sheet.$refs
      const height = dialog.offsetHeight
      dialog.style.transform = `translateY(${height}px)`

      setTimeout(() => {
        dialog.style.transform = ''
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  .handle-grabber {
    display: inline-block;
    width: 56px;
    height: 8px;
    border-radius: 50px;
  }
}

.v-card .bottom-sheet-content {
  max-height: 75vh;
  overflow-y: auto;
}

.v-card .overscroll {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  height: 100vh;
  content: ' ';
  transform: translateY(calc(100% - 1px));
}

.bottom-sheet-draggable.is-moving {
  transition-duration: 0.1s !important;
}
</style>
