<template>
  <v-snackbar
    v-bind="snackbarProps"
    :content-class="!!snackbarProps.custom ? 'custom-toast' : ''"
    @input="onSnackbarInput"
  >
    <component
      :is="activeToast.component"
      v-if="activeToast?.component"
      v-bind="activeToast.componentProps"
      @close="() => onSnackbarInput(false)"
    />
    <span v-else v-text="activeToast?.text" />
  </v-snackbar>
</template>

<script>
import { sleep } from '@/utils/helpers'

export default {
  inheritAttrs: false,
  props: {
    replaceActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toasts: [],
      show: false,
    }
  },
  computed: {
    activeToast() {
      return this.toasts[0]
    },
    snackbarProps() {
      const opts = {
        app: true,
        value: this.show,
        transition: 'slide-y-reverse-transition',
        color: 'mint25 mint600--text',
      }

      const { activeToast } = this
      if (!activeToast) return opts

      return {
        ...opts,
        ...activeToast.props,
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.onSnackbarOpen(this.activeToast)
      }
    },
  },
  created() {
    this.$bus.$on('toast:open', this.onOpenToast)
    this.$bus.$on('toast:close-all', () => {
      this.show = false
      this.toasts = []
    })
  },
  methods: {
    async onOpenToast(opts) {
      if (this.replaceActive && this.show) {
        await this.onSnackbarInput(false)
      }

      this.toasts.push({
        props: {
          timeout: 2e3,
        },
        ...opts,
      })

      if (!this.show) this.show = true
    },
    onSnackbarOpen() {
      // NOTE play sound?
    },
    async onSnackbarInput(open) {
      if (open) return

      this.show = false

      await this.$nextTick()
      await sleep(100)
      this.toasts.shift()

      if (this.toasts.length) {
        await this.$nextTick()
        this.show = true
      }
    },
  },
}
</script>

<style>
.custom-toast {
  padding: 0;
}
</style>
