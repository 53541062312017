<template>
  <BrandButton
    variant="secondary"
    color="neutral25 neutral900--text"
    :class="$vnode.data.staticClass"
    v-bind="{...btnProps}"
  >
    <PhChecks class="text-h5" />
    <span class="ml-3 d-none d-md-inline" v-text="$t('bulk.actions.select')" />
  </BrandButton>
</template>

<script>
import {
  PhChecks,
} from 'phosphor-vue'

export default {
  components: {
    PhChecks,
  },
  inheritAttrs: false,
  computed: {
    btnProps() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return { rounded: true, xLarge: true }
      }

      return { height: 48, width: 48, fab: true }
    },
  },
}
</script>
