import * as e from '@/api/utils/endpoints'
import axios from '@/plugins/axios'

const api = axios.create({
  baseURL: CONFIG.authUrl,
})

// auth service
export const deviceLogin = (device, ...o) => api.post(e.AUTH_DEVICE_LOGIN, device, ...o)
export const register = (form, ...o) => api.post(e.AUTH_USER_REGISTER, form, ...o)
export const login = (form, ...o) => api.post(e.AUTH_USER_LOGIN, form, ...o)
export const refresh = (form, ...o) => api.post(e.SESSION_REFRESH, form, ...o)
export const resetPassword = (form, ...o) => api.post(e.AUTH_USER_RECOVERY, form, ...o)
export const recoverAccount = (form, ...o) => api.post(e.AUTH_USER_RECOVERY_PASSWORD, form, ...o)

export const ssoGoogleAuth = (form, ...o) => api.post('/sso/google', form, ...o)
export const ssoFacebookAuth = (form, ...o) => api.post('/sso/facebook', form, ...o)
export const ssoAppleAuth = (form, ...o) => api.post('/sso/apple', form, ...o)

export const mfaSetup = (form, ...o) => api.post(e.MFA_SETUP, form, ...o)
export const mfaConfirm = (form, ...o) => api.post(e.MFA_CONFIRM, form, ...o)
export const mfaRemove = (form, ...o) => api.delete(e.MFA, { data: form }, ...o)
export const mfaRevoke = (form, ...o) => api.post(e.MFA_REVOKE, form, ...o)

// api service
export const activeSessions = opts => axios.get('/sessions/active', opts)
export const invalidateSession = (sessionId, ...o) => axios.post('/sessions/invalidate', { sessionId }, ...o)
export const changePassword = (form, ...o) => axios.patch(e.USER_PASSSWORD, form, ...o)

export const ssoGet = (...o) => axios.get('/user/sso', ...o)
export const ssoUnlink = (form, ...o) => axios.post('/user/sso/unlink', form, ...o)

export { api }
