// eslint-disable-next-line no-promise-executor-return
export const sleep = ms => new Promise(res => setTimeout(res, ms))

export function timedPromise(promise, ms) {
  let timer = null

  return Promise.race([
    new Promise((_, reject) => {
      timer = setTimeout(() => reject(new Error('TIMED_OUT')), ms)
    }),
    promise.then(value => {
      clearTimeout(timer)
      return value
    }),
  ])
}

export function debounce(fn, delay) {
  let timerId
  return function abc(...args) {
    if (timerId) clearTimeout(timerId)

    timerId = setTimeout(() => {
      fn.call(this, ...args)
      timerId = null
    }, delay)
  }
}

export const throttle = (fn, delay) => {
  let lastCall = 0
  return function abc(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) return
    lastCall = now
    // eslint-disable-next-line consistent-return
    return fn.call(this, ...args)
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const throttleEnsureLast = (fn, wait) => {
  let previouslyRun
  let queuedToRun

  return function invokeFn(...args) {
    const now = Date.now()

    queuedToRun = clearTimeout(queuedToRun)

    if (!previouslyRun || (now - previouslyRun >= wait)) {
      fn.call(this, ...args)
      previouslyRun = now
    } else {
      queuedToRun = setTimeout(invokeFn.bind(this, ...args), wait - (now - previouslyRun))
    }
  }
}

export function getRouteInheritedMeta(route) {
  let inheritedMeta = {}

  for (const r of route.matched) {
    inheritedMeta = { ...inheritedMeta, ...r.meta }
  }

  return inheritedMeta
}

export async function sha512Encode(text) {
  if (!window.crypto?.subtle) {
    const { default: sha512 } = await import('hash.js/lib/hash/sha/512')
    return sha512().update(text).digest('hex')
  }

  const encoder = new TextEncoder()
  const data = encoder.encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-512', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

export const tryJsonParse = str => {
  try {
    return JSON.parse(str)
  } catch (error) {
    return str
  }
}

export const tryJsonStringify = obj => {
  try {
    return JSON.stringify(obj)
  } catch (error) {
    return obj
  }
}

export const loadUrl = url => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  script.addEventListener('load', () => resolve(script), false)
  script.addEventListener('error', () => reject(script), false)
  document.body.appendChild(script)
})

export const loadUrls = urls => Promise.all(urls.map(loadUrl))

// NOTE caveat: larges files might be problematic, loaded into ram
export const downloadFile = (data, filename) => {
  const url = URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', filename)

  document.body.appendChild(link)
  link.click()
  setTimeout(() => link.remove(), 1e3)
}

export function isValidHttpUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  // only accept http protocols
  if (!['http:', 'https:'].includes(url.protocol)) return false

  // make sure hostname has at least one dot (we support ips too)
  if (!url.hostname.includes('.')) return false

  return true
}

export function getDeviceColorSchemePreference() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
}

export function getLocationQuery() {
  const { hash, search } = window.location
  if (search) return search

  const searchPos = hash.indexOf('?')
  return searchPos > -1 ? hash.substring(searchPos) : ''
}
