<template>
  <v-btn v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
    <BrandIcon class="text-h5 mr-2" name="google" />
    <slot />
  </v-btn>
</template>

<script>
import BrandIcon from './BrandIcon.vue'

export default {
  components: { BrandIcon },
  inheritAttrs: false,
  computed: {
    commonAttrs() {
      return {
        color: '#ffffff',
        class: [
          'text-none',
          'rounded-lg',
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn {
  border: 1px solid #ededed !important;
  color: #757575;
}
</style>
