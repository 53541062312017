import { Preferences } from '@capacitor/preferences'

export default {
  configure: Preferences.configure,
  get: async key => {
    if (BUILD_TARGET === 'extension') {
      const value = await (await import('webextension-polyfill')).storage.local.get(key)
      if (value[key]) return value[key]
    }

    return Preferences.get({ key }).then(i => JSON.parse(i.value)?.value ?? null)
  },
  set: async (key, value) => {
    if (BUILD_TARGET === 'extension') {
      // convert reactive objects to plain objects, or firefox extension storage gets messed up :))
      // eslint-disable-next-line no-underscore-dangle
      const normalizedValue = value.__ob__ ? { ...value } : value
      return (await import('webextension-polyfill')).storage.local.set({ [key]: normalizedValue })
    }

    return Preferences.set({ key, value: JSON.stringify({ value }) })
  },
  remove: async key => {
    if (BUILD_TARGET === 'extension') {
      return (await import('webextension-polyfill')).storage.local.remove(key)
    }

    return Preferences.remove({ key })
  },
}
