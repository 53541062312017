<template>
  <div class="search-activator">
    <v-expand-x-transition>
      <BrandTextField
        v-if="active || search" ref="input" v-model.trim="search"
        :placeholder="computedPlaceholder" variant="basic"
        :height="minified ? 48 : undefined" :dense="minified"
        @focus="focused = true"
        @keypress.enter="$refs.input?.blur()"
        @blur="active = false; focused = false"
      >
        <PhMagnifyingGlass
          v-if="minified ? focused : true"
          slot="prepend-inner"
          class="text-h5 ml-1 ml-sm-3 mr-1 mr-sm-2"
        />

        <template #append>
          <v-progress-circular
            v-if="loading" color="blue50"
            size="26" width="3" class="mt-1 mr-2"
            indeterminate
          />

          <BrandButton
            v-else-if="showXButton" icon
            @click="handleXButtonClick"
          >
            <PhXCircle class="text-h5 neutral400--text" weight="fill" />
          </BrandButton>
        </template>
      </BrandTextField>

      <BrandButton
        v-else
        variant="secondary" rounded x-large
        color="neutral25 neutral900--text"
        collapse-at="xs"
        v-bind="{...btnProps()}"
        @click="onSearch"
      >
        <PhMagnifyingGlass class="text-h5" />
        <span class="ml-3 d-none d-md-inline" v-text="$t('cta.search')" />
      </BrandButton>
    </v-expand-x-transition>
  </div>
</template>

<script>
import { PhMagnifyingGlass, PhXCircle } from 'phosphor-vue'
import { mapGetters } from 'vuex'

export default {
  components: { PhMagnifyingGlass, PhXCircle },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    loading: Boolean,
    minified: Boolean,
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      active: false,
      focused: false,
      search: this.value,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'device/isMobile',
      isNative: 'device/isNative',
      isExtension: 'device/isExtension',
      isExtensionPopup: 'device/isExtensionPopup',
      isExtensionMini: 'device/isExtensionMini',
    }),
    computedPlaceholder() {
      return this.placeholder ?? this.$t('form.field.search.label')
    },
    showXButton() {
      return this.isMobile || this.search || this.isNative || this.isExtension || this.isExtensionMini || this.isExtensionPopup
    },
  },
  watch: {
    value(s) {
      if (this.search === s) return
      this.search = s
    },
    search(s) {
      this.$emit('input', s)
    },
    focused(s) {
      this.$emit('focused', s)
    },
  },
  methods: {
    btnProps(collapse = true) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return { rounded: true, xLarge: true }
      }

      if (!collapse) {
        return { height: 48, rounded: true }
      }

      return { height: 48, width: 48, fab: true }
    },
    handleXButtonClick() {
      if (this.search) {
        this.active = true
        this.search = ''
      } else {
        this.active = false
        this.focused = false
      }
    },
    async onSearch() {
      this.active = true
      this.focused = true

      await this.$nextTick()
      this.$refs.input.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
  .brand-text-field {
    border-radius: 30px !important;

    :deep(.v-input__append-inner) {
      margin-top: 10px;
    }
  }

  .expand-x-transition {
    &-leave-active {
      position: absolute;
    }
  }
</style>
