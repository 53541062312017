<template>
  <v-snackbar
    v-bind="$attrs"
    :value="value"
    app rounded="0" :timeout="-1"
    min-width="100%"
    class="active-filters-notice cursor-pointer"
    content-class="full-width d-flex align-center"
    color="neutral800 neutral0--text"
  >
    <v-container class="max-app-width pa-0">
      <v-row dense align="center">
        <v-col cols="auto" class="d-flex align-center">
          <PhFadersHorizontal class="text-h5 mr-2" weight="fill" />
          <span v-text="$tc('common.filters_active', value?.length ?? 0)" />
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="d-flex align-center">
          <BrandButton icon text small>
            <PhCaretUp class="text-h6" />
          </BrandButton>
        </v-col>
      </v-row>
      <v-spacer />
    </v-container>
  </v-snackbar>
</template>

<script>
import { PhCaretUp, PhFadersHorizontal } from 'phosphor-vue'

export default {
  components: { PhCaretUp, PhFadersHorizontal },
  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, Array],
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.active-filters-notice :deep() {
  .v-snack__wrapper {
    margin: 0;
  }

  .v-snack__action {
    display: none;
  }
}
</style>
