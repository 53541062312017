import insane from 'insane'
import Languages from '@cospired/i18n-iso-languages'

Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'))
Languages.registerLocale(require('@cospired/i18n-iso-languages/langs/de.json'))

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false }
  const opt = { ...defaultOptions, ...options }
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language
  if (!navigatorLocale) {
    return undefined
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()
  return trimmedLocale
}

export function getSupportedLocales() {
  const langs = require.context('@/lang', false, /[a-z_-]+\.json$/, 'lazy')

  return langs.keys().reduce((acc, fileName) => {
    const lang = fileName.split('/').pop().replace(/\.\w+$/, '')
    acc[lang] = fileName
    return acc
  }, {})
}

export const TO_FLAG = {
  en: 'gb',
  de: 'de',
  fr: 'fr',
  it: 'it',
}

export const INSANE_CONFIG = {
  allowedClasses: { a: ['link'] },
  allowedAttributes: {
    ...insane.defaults.allowedAttributes,
    a: ['class', 'href', 'name', 'target', 'rel'],
  },
}

export { Languages }
