import {
  PhQuestion, PhDeviceMobile, PhPuzzlePiece, PhDesktop,
} from 'phosphor-vue'
import LoadingModel from '@/utils/store/LoadingModel'
import { formatDateRelativeNow } from '@/utils/date-format'
import { capitalizeFirstLetter } from '@/utils/helpers'
import User from './User'

const OS_MAP = {
  windows: 'Windows',
  mac: 'macOS',
  ios: 'iOS',
  android: 'Android',
}

class AuthSession extends LoadingModel {
  static entity = 'authsessions'

  static fields() {
    return {
      ...super.fields(),
      device: this.attr(null),
      createdAt: this.attr(null),
      sessionId: this.attr(null),
      userId: this.attr(null),
      user: this.belongsTo(User, 'userId'),
    }
  }

  get deviceIcon() {
    if (!this.device) return PhQuestion

    const { platform, os } = this.device
    if (platform === 'web') {
      if (['ios', 'android'].includes(os)) return PhDeviceMobile
      return PhDesktop
    }
    if (platform === 'extension') return PhPuzzlePiece
    return PhDeviceMobile
  }

  get deviceOS() {
    return OS_MAP[this.device?.os]
  }

  get devicePlatform() {
    return this.device?.platform
  }

  get deviceBrowser() {
    const browser = this.device?.browser

    if (browser && this.device?.platform === 'extension') {
      return `${browser} (Ext.)`
    }
    return browser
  }

  get relativeTime() {
    return locale => {
      const str = formatDateRelativeNow(new Date(this.createdAt), {
        lang: locale,
        addSuffix: true,
      })

      if (locale === 'de') return capitalizeFirstLetter(str)
      return str
    }
  }
}

export default AuthSession
