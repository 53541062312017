/* eslint-disable import/no-cycle */
import BaseModel from '@/utils/store/BaseModel'
import Collection from '@/store/models/collections/Collection'

class BookmarkTag extends BaseModel {
  static entity = 'bookmarktags'

  static fields() {
    return {
      ...super.fields(),
      name: this.string(),
      // relationships
      ownerId: this.attr(null),
      owner: this.belongsTo(Collection, 'ownerId'),
    }
  }
}

export default BookmarkTag
