<template>
  <v-switch class="brand-switch" v-bind="{ ...commonAttrs, ...$attrs }" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-switch>
</template>

<script>
const VARIANTS = {
  basic: {
    inset: true,
    ripple: false,
    color: 'teal600',
    classes: ['brand-switch--basic'],
  },
}
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    commonAttrs() {
      const variant = VARIANTS[this.variant]
      const classes = []
      const common = {
        hideDetails: 'auto',
      }

      if (!this.$attrs.label) {
        classes.push('no-label')
      }

      return {
        ...common,
        ...variant,
        class: [
          ...classes,
          ...(variant?.classes ?? []),
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--switch.brand-switch {

  &.no-label:deep(.v-input--selection-controls__input) {
    margin: 0;
  }

  &.brand-switch--basic:not(.v-input--is-disabled) {
    &:deep(.v-input--switch__thumb) {
      color: var(--v-neutral0-base) !important;
    }

    &.v-input--is-label-active:deep(.v-input--switch__track)  {
      opacity: 1;
    }
    &:not(.v-input--is-label-active):deep(.v-input--switch__track) {
      color: var(--v-neutral200-base) !important;
    }
  }

}

</style>
