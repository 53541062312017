export default [
  {
    path: '/account',
    name: 'account',
    components: {
      default: () => import(/* webpackChunkName: "account" */ '@/views/Account/Account.vue'),
      appbar: () => import(/* webpackChunkName: "bm-appbar" */ '@/components/appbars/AppBarDesktop.vue'),
      sidebar: () => import(/* webpackChunkName: "acc-sidebar" */ '@/views/Account/components/AccountNav.vue'),
      footer: () => import(/* webpackChunkName: "footer" */ '@/components/AppFooter.vue'),
    },
    meta: {
      requiresAuth: true,
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
      sidebarVisibility(vm) {
        return vm.$vuetify.breakpoint.smAndDown
      },
    },
    children: [
      // security
      {
        path: 'settings/security',
        name: 'security settings',
        component: () => import(
          /* webpackChunkName: "acc-settings-security" */
          '@/views/Account/Settings/Security.vue'
        ),
      },
      {
        path: 'settings/security/connections',
        component: () => import(
          /* webpackChunkName: "acc-settings-connections" */
          '@/views/Account/Settings/Connections.vue'
        ),
      },
      {
        path: 'settings/security/sessions',
        component: () => import(
          /* webpackChunkName: "acc-settings-sessions" */
          '@/views/Account/Settings/AuthSessions.vue'
        ),
      },
      {
        path: 'settings/security/mfa/setup',
        component: () => import(
          /* webpackChunkName: "acc-settings-mfa-setup" */
          '@/views/Account/Settings/mfa/setup.vue'
        ),
      },
      {
        path: 'settings/security/mfa/confirm',
        component: () => import(
          /* webpackChunkName: "acc-settings-mfa-confirm" */
          '@/views/Account/Settings/mfa/confirm.vue'
        ),
      },
      {
        path: 'settings/security/mfa/remove',
        component: () => import(
          /* webpackChunkName: "acc-settings-mfa-remove" */
          '@/views/Account/Settings/mfa/remove.vue'
        ),
      },
      // privacy
      {
        path: 'settings/privacy',
        name: 'privacy settings',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy" */
          '@/views/Account/Settings/Privacy.vue'
        ),
      },
      {
        path: 'settings/privacy/logs',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-logs" */
          '@/views/Account/Settings/ActivityLog.vue'
        ),
      },
      {
        path: 'settings/privacy/reset',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-reset" */
          '@/views/Account/Settings/Reset.vue'
        ),
      },
      {
        path: 'settings/privacy/delete',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-delete" */
          '@/views/Account/Settings/Delete.vue'
        ),
      },
      {
        path: 'settings/privacy/export',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-export" */
          '@/views/Account/Settings/Export/Export.vue'
        ),
      },
      {
        path: 'settings/privacy/export/confirm',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-export-conf" */
          '@/views/Account/Settings/Export/Confirm.vue'
        ),
      },
      {
        path: 'settings/privacy/import',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-import" */
          '@/views/Account/Settings/Import/Import.vue'
        ),
      },
      {
        path: 'settings/privacy/import/confirm',
        component: () => import(
          /* webpackChunkName: "acc-settings-privacy-import-conf" */
          '@/views/Account/Settings/Import/Confirm.vue'
        ),
      },
      {
        path: 'settings/app',
        name: 'app settings',
        component: () => import(
          /* webpackChunkName: "acc-settings-app" */
          '@/views/Account/Settings/App.vue'
        ),
      },
      {
        path: 'settings/newsletter',
        name: 'newsletter settings',
        component: () => import(
          /* webpackChunkName: "acc-settings-newsletter" */
          '@/views/Account/Settings/Newsletter.vue'
        ),
      },
      // legal
      {
        path: 'legal/imprint',
        name: 'imprint',
        component: () => import(
          /* webpackChunkName: "acc-legal-imprint" */
          '@/views/Account/Legal/Imprint.vue'
        ),
      },
      {
        path: 'legal/tos',
        name: 'terms of service',
        component: () => import(
          /* webpackChunkName: "acc-legal-terms" */
          '@/views/Account/Legal/TermsOfService.vue'
        ),
      },
      {
        path: 'legal/privacy',
        name: 'privacy',
        component: () => import(
          /* webpackChunkName: "acc-legal-privacy" */
          '@/views/Account/Legal/PrivacyPolicy.vue'
        ),
      },
      {
        path: 'legal/licenses',
        name: 'licenses',
        component: () => import(
          /* webpackChunkName: "acc-legal-licenses" */
          '@/views/Account/Legal/Licenses.vue'
        ),
      },
    ],
  },
  {
    path: '/email/confirm',
    component: () => import(
      /* webpackChunkName: "email-confirm" */
      '@/views/Account/EmailConfirm.vue'
    ),
    meta: {
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
]
