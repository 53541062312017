import {
  PhQuestion, PhSignOut, PhSignIn, PhExport,
  PhLock, PhBookmarkSimple, PhLink, PhUserCircleMinus, PhUserCirclePlus,
  PhList, PhUserCircle, PhChatCenteredText, PhUserList, PhThumbsUp,
} from 'phosphor-vue'
import BaseModel from '@/utils/store/BaseModel'
import { formatDateRelativeNow } from '@/utils/date-format'
import { capitalizeFirstLetter } from '@/utils/helpers'
import activityLogStrings from '@/utils/activity-log-strings'

class ActivityLog extends BaseModel {
  static entity = 'activitylogs'

  static fields() {
    return {
      ...super.fields(),
      type: this.attr(null),
      meta: this.attr(null),
      references: this.attr(null),
      createdAt: this.attr(null),
    }
  }

  get icon() {
    if (this.type === 'AUTH_SSO_LINK') return PhUserCirclePlus
    if (this.type === 'AUTH_SSO_UNLINK') return PhUserCircleMinus
    if (this.type.startsWith('AUTH_')) return PhLock
    if (this.type === 'USER_SESSION') return PhSignIn
    if (this.type === 'USER_SESSION_INVALIDATE') return PhSignOut

    // priority over collection
    if (this.type.includes('BOOKMARK_')) return PhBookmarkSimple
    if (this.type.includes('MEMBER')) return PhUserList

    if (this.type.startsWith('COMMENT_')) return PhChatCenteredText
    if (this.type.startsWith('LIKE_')) return PhThumbsUp
    if (this.type.startsWith('USER_')) return PhUserCircle
    if (this.type.startsWith('COLLECTION_LINK')) return PhLink
    if (this.type.startsWith('COLLECTION_')) return PhList
    if (this.type.startsWith('EXPORT_')) return PhExport

    return PhQuestion
  }

  get textPretty() {
    return i18n => {
      if (!activityLogStrings[this.type]) return activityLogStrings.UNKNOWN()
      return activityLogStrings[this.type]?.(i18n, this)
    }
  }

  get relativeTime() {
    return locale => {
      const str = formatDateRelativeNow(new Date(this.createdAt), {
        lang: locale,
        addSuffix: true,
      })

      // NOTE modify based on language if needed
      return capitalizeFirstLetter(str)
    }
  }
}

export default ActivityLog
