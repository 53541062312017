import axios from '@/plugins/axios'

export const get = async opts => {
  const { results, next } = await axios.get('/collections', opts)
  return {
    results: results.map(i => ({
      ...i,
      ownerId: i.owner,
      members: i.membersPreview.map(t => ({
        ...t,
        userId: t.user?._id,
        collectionId: i._id,
      })),
    })),
    next,
  }
}

export const create = (data, ...o) => axios.post('/collections', data, ...o)

export const getId = async (id, ...o) => {
  const collection = await axios.get(`/collections/${id}`, ...o)
  return {
    ...collection,
    ownerId: collection.owner,
    members: collection.membersPreview.map(t => ({
      ...t,
      userId: t.user?._id,
      collectionId: collection._id,
    })),
  }
}

export const edit = (id, data, ...o) => axios.patch(`/collections/${id}`, data, ...o)
export const remove = (id, ...o) => axios.delete(`/collections/${id}`, ...o)
export const removeBulk = (data, ...o) => axios.post('/collections/bulk/remove', data, ...o)

// members
export const members = async (cId, ...o) => {
  const { results, next } = await axios.get(`/collections/${cId}/members`, ...o)

  return {
    results: results.map(membership => ({
      ...membership,
      userId: membership.user?._id,
      collectionId: cId,
    })),
    next,
  }
}

export const inviteEmails = (cId, data, ...o) => axios.post(`/collections/${cId}/members`, data, ...o)
export const inviteEmailsBulk = (data, ...o) => axios.post('/collections/bulk/invite', data, ...o)
export const acceptInvite = (cId, data, ...o) => axios.post(`/collections/${cId}/members/accept`, data, ...o)
export const inviteInfo = (cId, data, ...o) => axios.post(`/collections/${cId}/members/info`, data, ...o)
export const removeMember = (cId, mId, ...o) => axios.delete(`/collections/${cId}/members/${mId}`, ...o)
export const removeMemberBulk = (data, ...o) => axios.post('/collections/bulk/remove-member', data, ...o)

// share link
export const shareLink = (cId, ...o) => axios.get(`/collections/${cId}/members/link`, ...o)
export const shareLinksBulk = (data, ...o) => axios.post('/collections/bulk/get-share-links', data, ...o)
export const shareLinkInfo = (cId, data, ...o) => axios.post(`/collections/${cId}/members/link/info`, data, ...o)
export const createShareLink = (cId, data, ...o) => axios.post(`/collections/${cId}/members/link`, data, ...o)
export const createShareLinkBulk = (data, ...o) => axios.post('/collections/bulk/create-shared-links', data, ...o)
export const removeShareLink = (cId, lId, ...o) => axios.delete(`/collections/${cId}/members/link/${lId}`, ...o)
export const editShareLink = (cId, lId, data, ...o) => axios.patch(`/collections/${cId}/members/link/${lId}`, data, ...o)
export const editShareLinkBulk = (data, ...o) => axios.post('/collections/bulk/edit-shared-links', data, ...o)
export const deleteShareLinkBulk = (data, ...o) => axios.post('/collections/bulk/delete-shared-links', data, ...o)
export const acceptShareLink = (cId, data, ...o) => axios.post(`/collections/${cId}/members/link/accept`, data, ...o)
