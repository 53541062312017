<template>
  <component
    :is="component"
    v-model="open"
    v-bind="componentProps"
    v-on="$listeners"
  >
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>

    <template #handle="slotData">
      <slot name="sheet-handle" v-bind="slotData" />
    </template>

    <v-card flat color="neutral25" class="rounded-lg">
      <slot v-if="!isDesktop" name="sheet-prepend">
        <h4
          v-if="sheetTitle"
          class="text--text text-h5 font-weight-semibold px-4 py-3"
          v-text="sheetTitle"
        />
      </slot>
      <slot />
    </v-card>
  </component>
</template>

<script>
import { VMenu } from 'vuetify/lib'

export default {
  inheritAttrs: true,
  props: {
    value: Boolean,
    sheetTitle: {
      type: String,
      default: null,
    },
    menuProps: {
      type: Object,
      default: null,
    },
    sheetProps: {
      type: Object,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      open: this.value,
    }
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    component() {
      if (this.isDesktop) return VMenu
      return 'bottom-sheet-draggable'
    },
    componentProps() {
      if (this.isDesktop) {
        return {
          offsetY: true,
          bottom: true,
          nudgeBottom: 12,
          rounded: 'lg',
          disabled: this.disabled,
          ...this.menuProps,
        }
      }

      return {
        inset: true,
        disabled: this.disabled,
        ...this.sheetProps,
      }
    },
  },
  watch: {
    value(open) {
      this.open = open
    },
    open(open) {
      this.$emit('input', open)
    },
  },
  methods: {
    close() {
      this.open = false
    },
  },
}
</script>
