<template>
  <component :is="svg" v-bind="$attrs" class="brand-flag" />
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    country: {
      type: String,
      required: true,
    },
  },
  computed: {
    svg() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/img/flags/${this.country}.svg?inline`)
    },
  },
}
</script>

<style lang="scss">
.brand-flag {
  height: 1.3em;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(black, 0.25);
}
</style>
