<template>
  <v-img v-bind="$attrs" :src="imageSrc" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    },
    imageSrc() {
      const { isDark, name } = this
      const theme = isDark ? 'dark' : 'light'

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/img/illustrations/${name}-${theme}.svg`)
    },
  },
}
</script>
