import * as e from '@/api/utils/endpoints'
import axios from '@/plugins/axios'

export const getMe = () => axios.get(e.USER_ME)
export const edit = (form, ...o) => axios.patch(e.USER, form, ...o)

export const editAvatar = (file, ...o) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post('/user/settings/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' }, ...o })
}
export const deleteAvatar = (form, ...o) => axios.delete('/user/settings/avatar', ...o)

export const resendEmailConfirmation = (...o) => axios.post(e.USER_EMAIL_RESEND, ...o)

export const activityLogGet = (...o) => axios.get('/user/activity-log', ...o)
export const activityLogExport = (form, ...o) => axios.post('/user/activity-log/export', form, ...o)
export const resetAccount = (form, ...o) => axios.post('/user/reset', form, ...o)
export const deleteAccount = (form, ...o) => axios.post('/user/delete', form, ...o)

export const settingsGet = (...o) => axios.get('/user/settings', ...o)
export const settingsUpdate = (...o) => axios.put('/user/settings', ...o)

export const emailAutocomplete = (...o) => axios.get('/user/autocomplete/emails', ...o)
