<template>
  <v-btn class="brand-btn" v-bind="{ ...commonAttrs, ...$attrs }" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-btn>
</template>

<script>
const VARIANTS = {
  basic: {
    outlined: true,
    classes: ['brand-btn--basic', 'neutral900--text'],
  },
  primary: {
    color: 'white--text',
    classes: ['brand-btn--primary'],
  },
  secondary: {
    depressed: true,
    color: 'blue25 blue600--text', // default
    classes: ['brand-btn--secondary'],
  },
}
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: null,
    },
    collapseAt: {
      type: String,
      default: null,
    },
  },
  computed: {
    commonAttrs() {
      const variant = VARIANTS[this.variant]
      const classes = ['text-none']
      const common = {
        // elevation: 0,
      }

      if (this.collapseAt && this.$vuetify.breakpoint[this.collapseAt]) {
        common.height = 48
        common.width = 48
        common.fab = true
      }

      return {
        ...common,
        ...variant,
        class: [
          ...classes,
          ...(variant?.classes ?? []),
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.brand-btn {
  letter-spacing: 0.03125em;

  &:not(.v-btn--rounded):not(.v-btn--round) {
    border-radius: 6px !important;
  }

  &.v-btn--is-elevated:not(:focus) {
    box-shadow: 0 1px 2px rgba(0, 53, 76, 0.16); // neutral900
  }

  &.brand-btn--primary:not(.v-btn--disabled) {
    background:
      linear-gradient(
        to right,
        var(--v-blue400-base),
        var(--v-teal400-base)
      );
  }

  &.brand-btn--basic {
    border-color: var(--v-neutral50-base);
  }

}

</style>
