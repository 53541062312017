import Vue from 'vue'

import plugins from '@/plugins'
import { api as authApi } from '@/api/auth'
import store from '@/store'
import setupInterceptors from '@/utils/axios-interceptors'

import '@mdi/font/css/materialdesignicons.css'
import '@/styles/styles.scss'

import App from './App.vue'

const {
  vuetify, router, preferences, axios, i18n,
} = plugins

setupInterceptors(axios)
setupInterceptors(authApi)

Vue.config.productionTip = false

//
document.documentElement.classList.add(`target--${BUILD_TARGET}`)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  async mounted() {
    if (process.env.NODE_ENV !== 'production') {
      window.vm = this
    }

    // this needs mounted hook
    await preferences.configure({ group: 'RubraStorage' })
    this.$bus.$emit('app:created', this)
  },
  render: h => h(App),
}).$mount('#app')
