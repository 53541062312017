import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from '@/styles/export.module.scss'
import { getDeviceColorSchemePreference } from '@/utils/helpers'

Vue.use(Vuetify)

const themes = {}

for (const [color, hex] of Object.entries(colors)) {
  const theme = color.split('-')[0]
  const name = color.split('-')[1]

  // check if color has shades
  if (name) {
    const shade = +color.split('-')[1].slice(-3)

    if (!themes[theme]) themes[theme] = {}

    themes[theme][name] = hex

    if (shade === 500) {
      themes[theme][name.slice(0, -3)] = hex
    }
  } else {
    themes[theme][color] = hex
  }
}

// initial theme to avoid blinking
const lastTheme = localStorage.getItem('lastTheme') ?? 'auto'
let isDark = lastTheme === 'dark'
if (lastTheme === 'auto') {
  isDark = getDeviceColorSchemePreference() === 'dark'
}

export default new Vuetify({
  theme: {
    dark: isDark,
    options: {
      customProperties: true,
      variations: false,
    },
    themes,
  },
})
