<template>
  <BrandButton
    v-if="!isDesktop || isExtensionPopup"
    variant="secondary"
    color="neutral25 neutral900--text"
    :class="$vnode.data.staticClass"
    v-bind="{...btnProps}"
  >
    <PhFadersHorizontal class="text-h5" />
    <span class="ml-3 d-none d-md-inline" v-text="$t('cta.filter')" />
  </BrandButton>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  PhFadersHorizontal,
} from 'phosphor-vue'

export default {
  components: {
    PhFadersHorizontal,
  },
  inheritAttrs: false,
  computed: {
    ...mapGetters({
      isExtensionPopup: 'device/isExtensionPopup',
      isDesktop: 'device/isDesktop',
    }),
    btnProps() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return { rounded: true, xLarge: true }
      }

      return { height: 48, width: 48, fab: true }
    },
  },
}
</script>
