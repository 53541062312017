export const EMAIL_RE = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export const BOOKMARK_STYLES = {
  cards: 'cards',
  tiles: 'tiles',
  list: 'list',
  headlines: 'headlines',
}

export const BOOKMARK_SORT_FIELDS = {
  createdAt: 'createdAt',
  title: 'title',
  domain: 'sld',
  commentsNumber: 'commentsNumber',
  commentsDate: 'commentsDate',
  likes: 'likes',
}

export const MAX_READ_TIME = 60

export const bookmarkFilterDefaults = () => ({
  search: '',
  tags: [],
  isFavorite: false,
  sorting: [BOOKMARK_SORT_FIELDS.createdAt, false],
  readTime: [0, MAX_READ_TIME],
  languages: [],
})

export const collectionBookmarkFilterDefaults = () => ({
  search: '',
  tags: [],
  sorting: [BOOKMARK_SORT_FIELDS.createdAt, false],
  readTime: [0, MAX_READ_TIME],
  languages: [],
})

export const STALE_TIME = 300e3

export const INVITE_LINK_RE = /^\/collections\/([a-f\d]+)\/invite-(link|email)\/([a-z0-9-]+)/i

export const THEME_NAMES = {
  auto: 'page.account.app_settings.theme.auto',
  light: 'page.account.app_settings.theme.light',
  dark: 'page.account.app_settings.theme.dark',
}

export const MAX_IMPORT_LIMIT = 1000

export const AddToCollectionMethod = {
  MOVE: 'move',
  COPY: 'copy',
}

export const HOW_TO_UPDATE_APP_LINKS = {
  android: {
    en: 'https://rubra.com/help/updating-rubra/android-native-app/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-nativen-android-app/',
  },
  ios: {
    en: 'https://rubra.com/help/updating-rubra/ios-native-app/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-nativen-ios-app/',
  },
  firefox: {
    en: 'https://rubra.com/help/updating-rubra/firefox-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisierung-der-firefox-browsererweiterung/',
  },
  safari: {
    en: 'https://rubra.com/help/updating-rubra/safari-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-safari-browsererweiterung/',

  },
  edge: {
    en: 'https://rubra.com/help/updating-rubra/edge-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-edge-browsererweiterung/',
  },
  chrome: {
    en: 'https://rubra.com/help/updating-rubra/chrome-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-chrome-browsererweiterung/',
  },
  // Web-App (Should never show as it is updated automatically)
  web: {
    en: 'rubra.com/help/updating-rubra/web-app/',
    de: 'rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-web-app/',
  },
}

export const UPDATE_APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.rubra.appclient',
  ios: 'https://apps.apple.com/app/rubra-bookmark-manager/id1673761012',
  firefox: 'about:addons',
  safari: 'safari-extension://',
  edge: 'edge://extensions/',
  chrome: 'chrome://extensions/',
}

export const UPDATE_NEWS_LINKS = {
  de: 'https://rubra.com/de/update/',
  en: 'https://rubra.com/update/',
}

export const DEFAULT_DURATION = '30d'
export const DEACTIVATION_DURATION = '-1h'
