/* eslint-disable import/no-cycle */
import LoadingModel from '@/utils/store/LoadingModel'
import User from '@/store/models/user/User'
import Collection from '@/store/models/collections/Collection'

export const INVITE_STATUS = {
  pending: 0,
  accepted: 1,
  declined: 2,
}

class CollectionMember extends LoadingModel {
  static entity = 'collectionmembers'

  static fields() {
    return {
      ...super.fields(),
      userId: this.string(),
      invitedById: this.string(),
      collectionId: this.string(),
      email: this.string(),
      status: this.number(),
      isOwner: this.boolean(),
      // relationships
      user: this.belongsTo(User, 'userId'),
      invitedBy: this.belongsTo(User, 'invitedById'),
      collection: this.belongsTo(Collection, 'collectionId'),
    }
  }
}

export default CollectionMember
