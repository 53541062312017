<script>
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'

// TODO rewrite this to be a stack of event handlers that can be pushed and popped

export default {
  data() {
    return {
      bbm: {
        nativeHandler: null,
        routeHandler: null,
      },
    }
  },
  beforeDestroy() {
    this.destroyBackButtonDetector()
  },
  methods: {
    async setupBackButtonDetector() {
      this.destroyBackButtonDetector()

      // TODO check how ios is handled
      if (Capacitor.isNativePlatform()) {
        this.bbm.nativeHandler = await App.addListener('backButton', this.onBackButtonPressed.bind(this, 'native'))
      }

      // route guard to stop web back button
      this.bbm.routeHandler = this.$router.beforeEach((to, from, next) => {
        this.onBackButtonPressed('router', to)
        next(false)
      })
    },
    destroyBackButtonDetector() {
      this.bbm.nativeHandler?.remove()
      this.bbm.routeHandler?.()

      this.bbm.nativeHandler = null
      this.bbm.routeHandler = null
    },
    onBackButtonPressed() {
      console.warn('No action specified for back button handler')
    },
  },
}
</script>
