import User from '@/store/models/user/User'

export default {
  setUserProp(state, [prop, val]) {
    User.update({
      where: state.userId,
      data: { [prop]: val },
    })
  },
  incUserProp(state, [prop, val]) {
    if (!prop) return

    const user = User.find(state.userId)
    if (!user) return

    User.update({
      where: user.id,
      data: { [prop]: user[prop] + val },
    })
  },
  resetAuth(state) {
    User.delete(state.userId)

    state.sessionId = null
    state.userId = null

    // undefined removes local storage
    state.accessToken = undefined
    state.refreshToken = undefined
    state.collectionIdCache = undefined
  },
}
