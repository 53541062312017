import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'

const api = axios.create({
  baseURL: CONFIG.apiUrl,
  paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' }),
  timeout: 60e3, // NOTE adapt this to current network conditions?
})

Vue.prototype.$api = api

export default api
