<template>
  <v-avatar
    v-bind="$attrs" color="neutral200 white--text"
    v-on="$listeners"
  >
    <v-img v-if="user?.avatar" :src="user.avatarUrl" />
    <span v-else-if="user?.username" class="username text-uppercase" v-text="user.username[0]" />
    <PhUser v-else class="avatar-icon text-h5" />

    <slot />
  </v-avatar>
</template>

<script>
import { PhUser } from 'phosphor-vue'

export default {
  components: { PhUser },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-avatar {
  font-size: 16px;
}

.avatar-icon {
  width: 24px;
}
</style>
