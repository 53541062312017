const OS_MAP = {
  windows: 'Windows',
  mac: 'macOS',
  ios: 'iOS',
  android: 'Android',
}

const SSO_PROVIDERS = {
  google: 'Google',
  apple: 'Apple ID',
  facebook: 'Facebook',
}

function getDeviceData(device = {}) {
  const { platform, browser } = device
  const os = OS_MAP[device.os] ?? device.os
  const isExtension = platform === 'extension'

  return {
    platform, os, browser, isExtension,
  }
}

export default {
  USER_SESSION(i18n, entry) {
    const device = getDeviceData(entry.references.session?.device)
    const { browser, os } = device

    if (device.isExtension) {
      return i18n.mdt('page.activity_logs.log.USER_SESSION.extension', { browser })
    }

    if (device.platform !== 'web') {
      return i18n.mdt('page.activity_logs.log.USER_SESSION.native', { os })
    }

    return i18n.mdt('page.activity_logs.log.USER_SESSION.web', { os, browser })
  },
  USER_SESSION_INVALIDATE(i18n, entry) {
    const device = getDeviceData(entry.references.session?.device)
    const { browser, os } = device

    if (device.isExtension) {
      return i18n.mdt('page.activity_logs.log.USER_SESSION_INVALIDATE.extension', { browser })
    }

    if (device.platform !== 'web') {
      return i18n.mdt('page.activity_logs.log.USER_SESSION_INVALIDATE.native', { os })
    }

    return i18n.mdt('page.activity_logs.log.USER_SESSION_INVALIDATE.web', { os, browser })
  },
  USER_SESSION_REFRESH(i18n) {
    // NOTE not used
    return i18n.mdt('page.activity_logs.log.USER_SESSION_REFRESH')
  },
  USER_EDIT(i18n) {
    return i18n.t('page.activity_logs.log.USER_EDIT')
  },
  USER_SETTINGS_EDIT(i18n) {
    return i18n.t('page.activity_logs.log.USER_SETTINGS_EDIT')
  },
  USER_PASSWORD_RESET_REQUEST(i18n) {
    return i18n.mdt('page.activity_logs.log.USER_PASSWORD_RESET_REQUEST')
  },
  USER_PASSWORD_RESET(i18n) {
    return i18n.mdt('page.activity_logs.log.USER_PASSWORD_RESET')
  },
  USER_PASSWORD_CHANGE(i18n) {
    return i18n.mdt('page.activity_logs.log.USER_PASSWORD_CHANGE')
  },
  USER_EMAIL_CONFIRM(i18n) {
    return i18n.t('page.activity_logs.log.USER_EMAIL_CONFIRM')
  },
  USER_EMAIL_RESEND_CONFIRM(i18n) {
    return i18n.t('page.activity_logs.log.USER_EMAIL_RESEND_CONFIRM')
  },
  USER_ACCOUNT_CREATE(i18n) {
    return i18n.mdt('page.activity_logs.log.USER_ACCOUNT_CREATE')
  },
  USER_ACCOUNT_RESET(i18n) {
    return i18n.mdt('page.activity_logs.log.USER_ACCOUNT_RESET')
  },
  USER_ACCOUNT_DELETE(i18n) {
    // NOTE user will never see this
    return i18n.mdt('page.activity_logs.log.USER_ACCOUNT_DELETE')
  },
  USER_AVATAR_CHANGE(i18n) {
    return i18n.t('page.activity_logs.log.USER_AVATAR_CHANGE')
  },

  AUTH_MFA_SETUP(i18n) {
    return i18n.t('page.activity_logs.log.AUTH_MFA_SETUP')
  },
  AUTH_MFA_CONFIRM(i18n) {
    return i18n.t('page.activity_logs.log.AUTH_MFA_CONFIRM')
  },
  AUTH_MFA_REMOVE(i18n) {
    return i18n.mdt('page.activity_logs.log.AUTH_MFA_REMOVE')
  },
  AUTH_MFA_REVOKE(i18n) {
    return i18n.mdt('page.activity_logs.log.AUTH_MFA_REVOKE')
  },

  AUTH_SSO_LINK(i18n, entry) {
    const provider = SSO_PROVIDERS[entry.meta.public?.provider]
    return i18n.mdt('page.activity_logs.log.AUTH_SSO_LINK', { provider })
  },
  AUTH_SSO_UNLINK(i18n, entry) {
    const provider = SSO_PROVIDERS[entry.meta.public?.provider]
    return i18n.mdt('page.activity_logs.log.AUTH_SSO_UNLINK', { provider })
  },

  BOOKMARK_CREATE(i18n, entry) {
    const title = entry.references.bookmark?.title
    return i18n.mdt('page.activity_logs.log.BOOKMARK_CREATE', { title })
  },
  BOOKMARK_EDIT(i18n, entry) {
    const title = entry.references.bookmark?.title
    const isFavorite = entry.meta.public?.isFavorite

    if (isFavorite !== undefined) {
      return isFavorite
        ? i18n.mdt('page.activity_logs.log.BOOKMARK_FAVORITE', { title })
        : i18n.mdt('page.activity_logs.log.BOOKMARK_UNFAVORITE', { title })
    }

    return i18n.mdt('page.activity_logs.log.BOOKMARK_EDIT', { title })
  },
  BOOKMARK_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    return i18n.mdt('page.activity_logs.log.BOOKMARK_DELETE', { title })
  },
  BOOKMARK_RESTORE(i18n, entry) {
    const title = entry.references.bookmark?.title
    return i18n.mdt('page.activity_logs.log.BOOKMARK_RESTORE', { title })
  },
  BOOKMARK_FULL_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    return i18n.mdt('page.activity_logs.log.BOOKMARK_FULL_DELETE', { title })
  },

  COLLECTION_CREATE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_CREATE', { name })
  },
  COLLECTION_EDIT(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_EDIT', { name })
  },
  COLLECTION_DELETE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_DELETE', { name })
  },

  COLLECTION_MEMBER_ADD(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_MEMBER_ADD', { name })
  },
  COLLECTION_MEMBER_DELETE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_MEMBER_DELETE', { name })
  },
  COLLECTION_MEMBER_JOIN(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_MEMBER_JOIN', { name })
  },
  COLLECTION_MEMBER_LEAVE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_MEMBER_LEAVE', { name })
  },

  COLLECTION_LINK_CREATE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_LINK_CREATE', { name })
  },
  COLLECTION_LINK_EDIT(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_LINK_EDIT', { name })
  },
  COLLECTION_LINK_DELETE(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_LINK_DELETE', { name })
  },
  COLLECTION_LINK_JOIN(i18n, entry) {
    const name = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_LINK_JOIN', { name })
  },

  COLLECTION_BOOKMARK_CREATE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_CREATE', { title, collection })
  },
  COLLECTION_BOOKMARK_COPY(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_COPY', { title, collection })
  },
  COLLECTION_BOOKMARK_EDIT(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_EDIT', { title, collection })
  },
  COLLECTION_BOOKMARK_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_DELETE', { title, collection })
  },
  COLLECTION_BOOKMARK_RESTORE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_RESTORE', { title, collection })
  },
  COLLECTION_BOOKMARK_FULL_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name
    return i18n.mdt('page.activity_logs.log.COLLECTION_BOOKMARK_FULL_DELETE', { title, collection })
  },

  COMMENT_CREATE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name

    if (collection) {
      return i18n.mdt('page.activity_logs.log.COMMENT_CREATE.comment', { title, collection })
    }

    return i18n.mdt('page.activity_logs.log.COMMENT_CREATE.note', { title })
  },
  COMMENT_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name

    if (collection) {
      return i18n.mdt('page.activity_logs.log.COMMENT_DELETE.comment', { title, collection })
    }

    return i18n.mdt('page.activity_logs.log.COMMENT_DELETE.note', { title })
  },

  LIKE_CREATE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name

    return i18n.mdt('page.activity_logs.log.LIKE_CREATE', { title, collection })
  },
  LIKE_DELETE(i18n, entry) {
    const title = entry.references.bookmark?.title
    const collection = entry.references.collection?.name

    return i18n.mdt('page.activity_logs.log.LIKE_DELETE', { title, collection })
  },

  NOTIFICATION_SUBSCRIPTION_EDIT(i18n) {
    return i18n.mdt('page.activity_logs.log.NOTIFICATION_SUBSCRIPTION_EDIT')
  },
  NOTIFICATION_SUBSCRIPTION_DISABLED(i18n) {
    return i18n.mdt('page.activity_logs.log.NOTIFICATION_SUBSCRIPTION_DISABLED')
  },

  EXPORT_BOOKMARKS(i18n) {
    return i18n.mdt('page.activity_logs.log.EXPORT_BOOKMARKS')
  },
  EXPORT_ACTIVITYLOGS(i18n) {
    return i18n.mdt('page.activity_logs.log.EXPORT_ACTIVITYLOGS')
  },

  IMPORT_BOOKMARKS(i18n) {
    return i18n.mdt('page.activity_logs.log.IMPORT_BOOKMARKS')
  },

  UNKNOWN(i18n) {
    return i18n.mdt('page.activity_logs.log.UNKNOWN')
  },
}
