import EventBus from '@/plugins/eventbus'
import Preferences from '@/plugins/preferences'
import { stateGetters, stateSetters, watchGetterAndPersist } from '@/utils/store/store-utils'

import User from '@/store/models/user/User'
import Bookmark from '@/store/models/bookmarks/Bookmark'

import { MAX_IMPORT_LIMIT } from '@/utils/constants'
import mutations from './mutations'
import actions from './actions'

const defaultState = {
  userId: undefined, // initialize as undefined to say it was not fetched yet
  accessToken: null,
  refreshToken: null,
  sessionId: null, // NOTE might not be needed yet
  onboardingConsent: undefined,
  collectionIdCache: undefined,
}

export default {
  name: 'auth',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
    session(state) {
      if (!state.userId) return null
      return User.query().whereId(state.userId).withAll().first()
    },
    hasFullAccount(_, getters) {
      return getters.session?.isFullAccount ?? false
    },
    showGuestAccountNotice(_, getters) {
      if (getters.session?.isFullAccount) return false

      const ownerId = getters.collectionId
      if (!ownerId) return false

      // NOTE not ideal cuz FE-only but good enough
      const bookmarkCount = Bookmark.query().where('ownerId', ownerId).count()
      return bookmarkCount >= 1 && bookmarkCount <= 3
    },
    collectionId(s, getters) {
      return getters.session?.collectionId ?? s.collectionIdCache
    },
    importCredits(s, getters) {
      return getters.session?.importCredits ?? MAX_IMPORT_LIMIT
    },
  },
  mutations: {
    ...stateSetters(defaultState),
    ...mutations,
  },
  actions,
}

async function initAuth(vm, accessToken) {
  if (!accessToken) {
    vm.$store.commit('auth/setUserId', null)
  } else {
    try {
      await vm.$store.dispatch('auth/checkOrRefreshAuth')

      // if device is online, try to get up-to-date user data
      if (vm.$store.getters['device/online']) {
        await vm.$store.dispatch('auth/getMe')
      }
    } catch (error) {
      vm.$error.toast(error)

      // the error system above handles SESSION_EXPIRED log out
      if (error.message !== 'SESSION_EXPIRED') {
        // if any error happens during auth boot, we log user out to be safe and avoid user getting stuck in loop
        vm.$store.dispatch('auth/logout', false)
      }
    }
  }
}

EventBus.$once('app:created', async vm => {
  const [accessToken, refreshToken, onboardingConsent, collectionId] = await Promise.all([
    Preferences.get('accessToken'),
    Preferences.get('refreshToken'),
    Preferences.get('onboardingConsent'),
    Preferences.get('collectionId'),
  ])

  vm.$store.commit('auth/setAccessToken', accessToken)
  vm.$store.commit('auth/setRefreshToken', refreshToken)
  vm.$store.commit('auth/setOnboardingConsent', onboardingConsent)
  vm.$store.commit('auth/setCollectionIdCache', collectionId)

  // state persisters
  watchGetterAndPersist(vm.$store, 'auth/accessToken', 'accessToken')
  watchGetterAndPersist(vm.$store, 'auth/refreshToken', 'refreshToken')
  watchGetterAndPersist(vm.$store, 'auth/onboardingConsent', 'onboardingConsent')
  watchGetterAndPersist(vm.$store, 'auth/collectionId', 'collectionId')

  // chrome extension auth refresh
  if (BUILD_TARGET === 'extension') {
    (await import('webextension-polyfill')).runtime.onMessage.addListener(async ({ event, data }) => {
      console.log(`Received event ${event} from extension background worker`, data)

      switch (event) {
        case 'app:auth-refresh':
          await vm.$store.dispatch('auth/handleAuthTokens', data)
          EventBus.$emit('extension:auth-refresh', data)
          break
        case 'app:sync-device-id':
          await vm.$store.dispatch('device/setDeviceId', data.id)
          break
        default: break
      }
    })
  }

  // init auth
  initAuth(vm, accessToken)

  // re-try auth if device comes online and no session data
  vm.$store.watch((_, getters) => getters['device/online'], () => {
    if (!vm.$store.getters['auth/session']) {
      console.log('[AUTH] Device is online, re-trying auth')
      initAuth(vm, accessToken)
    }
  })
})
