<template>
  <div class="modal-factory">
    <v-dialog
      v-if="stack.length"
      :value="activeDialog.open"
      v-bind="activeDialog.props"
      @input="onDialogInput($event)"
    >
      <component
        :is="activeDialog.component"
        v-bind="activeDialog.payload"
        @return="onDialogReturnValue"
        @close="onDialogInput(false)"
      />
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { sleep } from '@/utils/helpers'

// NOTE dialogs could have #dialog=name urls associated with them if wanted
// would just have to set/unset them on input change

// dialogs render as a stack, always showing the latest addition traveling backwards

export default {
  inheritAttrs: false,
  data() {
    return {
      stack: [],
    }
  },
  computed: {
    activeDialog() {
      return this.stack[this.stack.length - 1]
    },
  },
  created() {
    // if (!this.$modal) throw new Error('App may only have one instance of ModalFactory')

    // NOTE not sure if this is a hack
    Vue.prototype.$modal = this
  },
  methods: {
    async open(name, opts = {}) {
      if (!name && !opts.component) throw new Error('No dialog component resolvable')

      const { default: component } = await import(`@/dialogs/${name}.vue`)

      const props = {
        ...component.dialogProps,
        ...opts.props,
      }

      const vm = this

      const dialog = {
        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
        open: true,
        component,
        props,
        payload: opts.payload,
        remove() {
          this.open = false

          // remove from stack if not current dialog
          if (vm.activeDialog?.id !== this.id) {
            vm.stack = vm.stack.filter(i => i.id !== this.id)
          }
        },
      }

      // if dialog active, transition to new dialog first
      // NOTE this will wipe any component state in old dialog
      // to get around this, we could v-for all dialogs and only show dialog with activeDialog id
      if (this.stack.length) {
        const currDialog = this.activeDialog
        currDialog.open = false
        sleep(300)
          .then(() => this.stack.push(dialog))
          .then(() => { currDialog.open = true })
      } else {
        this.stack.push(dialog)
      }

      if (props.returnValue) {
        dialog.result = new Promise((res, rej) => {
          dialog.resolve = res
          dialog.reject = rej
        })
      }

      return dialog
    },
    onDialogInput(value) {
      if (value) return // dont handle opens
      const { activeDialog } = this

      // resolve returnValue promise with null
      if (activeDialog.result) activeDialog.resolve(null)

      activeDialog.open = false
      sleep(300).then(() => this.stack.pop())
    },
    onDialogReturnValue({ data, reject }) {
      if (this.activeDialog.result) {
        if (reject) this.activeDialog.reject(data)
        else this.activeDialog.resolve(data)
      }
    },
  },
}
</script>
