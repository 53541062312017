<template>
  <v-card flat color="transparent" class="bookmark-item--card d-flex flex-column">
    <BulkSelectCheckbox
      v-if="isBulkActionsActive"
      class="checkbox ml-3 mt-3"
      :checked="isSelected"
      @click.native="toggleSelect"
    />
    <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
    <a
      :href="bookmark.brandedUrl"
      target="_blank" rel="noopener" class="bookmark-thumbnail-container"
      :title="bookmark.title" @click="onLinkClick"
    >
      <BookmarkThumbnail :bookmark="bookmark" />
    </a>

    <v-card-title
      class=" px-0 pt-3 pb-1 bookmark-headline text-body-1 font-weight-semibold neutral900--text"
      :tag="isBulkActionsActive ? 'div': 'a'" :href="bookmark.brandedUrl" target="_blank" rel="noopener"
      @click="toggleSelect"
    >
      <v-progress-circular
        v-if="bookmark.isLoading && !bookmark.hasSufficientData"
        indeterminate size="16" color="blue400" width="3"
        class="mr-1"
      />
      {{ bookmark.title ?? bookmark.domain }}
    </v-card-title>

    <div class="neutral600--text d-flex align-center mb-3 text-caption">
      <BookmarkSource :bookmark="bookmark" />
      <span class="mx-1">&middot;</span>
      <BookmarkDate :value="bookmark.createdAt" />
    </div>

    <BookmarkTags v-if="bookmark.tags.length" class="mb-3" :tags="bookmark.tags" v-on="$listeners" />

    <v-spacer />

    <BookmarkActions
      :key="bookmark.id" :bookmark="bookmark"
      :user-id="userId"
      class="text--text" v-on="$listeners"
    />
  </v-card>
</template>

<script>
import BookmarkSource from './BookmarkSource'
import BookmarkActions from './BookmarkActions'
import BookmarkDate from './BookmarkDate'
import BookmarkTags from './BookmarkTags'
import BookmarkThumbnail from './BookmarkThumbnail'
import BulkActionsMixin from '../mixins/BulkActions'

export default {
  components: {
    BookmarkActions,
    BookmarkThumbnail,
    BookmarkTags,
    BookmarkDate,
    BookmarkSource,
  },
  mixins: [BulkActionsMixin],
  props: {
    bookmark: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    isGrid() {
      return this.$vuetify.breakpoint.smAndUp
    },
    isSelected() {
      return this.bookmark.isSelected
    },
  },
  methods: {
    onLinkClick(event) {
      if (this.isBulkActionsActive) {
        event.preventDefault()
        this.toggleSelect()
      }
    },
    toggleSelect() {
      this.$emit('toggle-select', this.bookmark)
    },
  },
}
</script>

<style lang="scss" scoped>
.bookmark-item--card {
  height: 100%;

  .checkbox {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

.bookmark-thumbnail-container {
  position: relative;

  .favorite-button {
    position: absolute;
    top: 5px;
    right: 6px;
    background-color: rgba(white, 0.5) !important;
  }
}

.bookmark-headline {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
}
</style>
