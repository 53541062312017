import Vue from 'vue'
import EventBus from './eventbus'

function openToast(opts) {
  EventBus.$emit('toast:open', opts)
}

Vue.prototype.$toast = {
  open: openToast,
  closeAll: () => EventBus.$emit('toast:close-all'),
  error: (text, opts) => openToast({
    text,
    ...opts,
    props: {
      color: 'red25 red600--text',
      timeout: 4e3,
      ...opts?.props,
    },
  }),
  info: (text, opts) => openToast({
    text,
    ...opts,
    props: {
      color: 'blue25 blue600--text',
      ...opts?.props,
    },
  }),
  success: (text, opts) => openToast({
    text,
    ...opts,
    props: {
      color: 'lime25 lime600--text',
      ...opts?.props,
    },
  }),
}

export default true
