import { stateGetters, stateSetters } from '@/utils/store/store-utils'
import EventBus from '@/plugins/eventbus'

const defaultState = {
  UnderMaintenance: false,
}

export default {
  name: 'features',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchFeatures({ commit }) {
      const { features } = await this.$api.features.fetchFeatures()
      commit('setUnderMaintenance', features.UnderMaintenance)
    },
  },
}

EventBus.$once('app:created', async vm => {
  vm.$store.dispatch('features/fetchFeatures').catch(console.error)
})
