<template>
  <v-file-input
    v-model="modelValue"
    class="brand-file-drop rounded-lg"
    :class="{'is-dragging': dragging}"
    :accept="accept"
    :disabled="disabled"
    v-bind="$attrs" outlined prepend-icon=""
    :multiple="multiple" hide-details="auto"
    @drop.native.prevent="addDropFile"
    @dragover.native.prevent="dragging = true"
    @dragenter.native.prevent="dragging = true"
    @dragleave.native.prevent="dragging = false"
    @change="onFileInput"
  >
    <template #prepend-inner>
      <slot>
        <div class="text-center mx-auto pa-6">
          <PhFileText class="teal400--text text-h3 mb-4" weight="fill" />
          <h4 class="text-body-2 neutral900--text font-weight-semibold" v-text="titleComputed" />
          <p v-if="subtitle" class="text-caption neutral400--text mb-0" v-text="subtitle" />
        </div>
      </slot>
    </template>
  </v-file-input>
</template>

<script>
// TODO check if special implementation required on native
import { PhFileText } from 'phosphor-vue'

export default {
  components: { PhFileText },
  inheritAttrs: false,
  props: {
    value: {
      type: [File, Array],
      default: null,
    },
    accept: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    multiple: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragging: false,
      modelValue: this.value,
    }
  },
  computed: {
    titleComputed() {
      return this.title ?? this.$t('component.filedropper.title')
    },
  },
  watch: {
    value(val) {
      this.modelValue = val
    },
  },
  methods: {
    addDropFile(e) {
      this.dragging = false

      let files = Array.from(e.dataTransfer.files)
      files = files.filter(i => this.validateFile(i))
      if (!files.length) return

      if (this.multiple) {
        if (this.replace) {
          this.modelValue.push(...files)
        } else {
          this.modelValue = files
        }
      } else {
        const [file] = files
        this.modelValue = file
      }

      this.$emit('input', this.modelValue)
    },
    onFileInput() {
      this.$emit('input', this.modelValue)
    },
    validateFile(file) {
      if (!this.accept) return true

      return this.accept
        .replace(/\s/g, '').split(',')
        .some(accept => new RegExp(accept.replace('*', '.*')).test(file.type))
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-file-drop {
  &.v-text-field--outlined:not(.v-input--is-disabled):not(.v-input--has-state):not(.v-input--is-focused) {
    &:deep(.v-input__control) {
      fieldset {
        border: 2px dashed var(--v-neutral200-base);
      }
    }

    &:hover,
    &.is-dragging {
      &:deep(.v-input__control) {
        fieldset {
          border-color: var(--v-teal900-base);
        }
      }
    }
  }

  &:deep(.v-input__control) {
    .v-input__prepend-inner {
      width: 100%;
      margin: 0;
    }

    .v-text-field__slot {
      @include overlay;

      cursor: pointer;

      .v-file-input__text {
        display: none;
      }
    }

    .v-input__append-inner {
      display: none;
    }
  }
}

</style>
