export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      modelValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.modelValue = val
    },
    modelValue(val) {
      this.$emit('input', val)
    },
  },
}
