<template>
  <v-card flat color="transparent" class="bookmark-item--headline d-flex align-start">
    <BulkSelectCheckbox
      v-if="isBulkActionsActive"
      class="mx-3 mt-0"
      :checked="isSelected"
      @click.native="toggleSelect"
    />
    <div class="full-width">
      <div class="neutral400--text d-flex align-center mb-2 text-caption">
        <BookmarkSource :bookmark="bookmark" />
        <span class="mx-1">&middot;</span>
        <BookmarkDate :value="bookmark.createdAt" />
      </div>

      <v-card-title
        class="bookmark-headline pa-0 mb-3 text-body-1 font-weight-semibold neutral900--text"
        tag="a" :href="bookmark.brandedUrl" target="_blank" rel="noopener"
        @click="onLinkClick"
      >
        <v-progress-circular
          v-if="bookmark.isLoading && !bookmark.hasSufficientData"
          indeterminate size="16" color="blue400" width="3"
          class="mr-2"
        />
        <span v-text="bookmark.title ?? bookmark.domain" />
      </v-card-title>
      <BookmarkTags v-if="bookmark.tags.length" class="mb-3" :tags="bookmark.tags" v-on="$listeners" />

      <BookmarkActions
        :bookmark="bookmark" :user-id="userId"
        class="text--text" v-on="$listeners"
      />
    </div>
  </v-card>
</template>

<script>
import BookmarkSource from './BookmarkSource'
import BookmarkActions from './BookmarkActions'
import BookmarkDate from './BookmarkDate'
import BookmarkTags from './BookmarkTags'
import BulkActionsMixin from '../mixins/BulkActions'

export default {
  components: {
    BookmarkActions,
    BookmarkTags,
    BookmarkDate,
    BookmarkSource,
  },
  mixins: [BulkActionsMixin],
  props: {
    bookmark: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    isSelected() {
      return this.bookmark.isSelected
    },
  },
  methods: {
    onLinkClick(event) {
      if (this.isBulkActionsActive) {
        event.preventDefault()
        this.toggleSelect()
      }
    },
    toggleSelect() {
      this.$emit('toggle-select', this.bookmark)
    },
  },
}
</script>

<style lang="scss" scoped>
.bookmark-headline {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

.bookmark-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}
</style>
