// NOTE this file is kind of redundant, api/* files are already the only
// part of the app that interacts with backend, so we can just hardcode the endpoints there

// app
export const CONGA = '/conga'

// auth
export const AUTH_DEVICE_LOGIN = '/device/login'
export const AUTH_USER_LOGIN = '/user/login'
export const AUTH_USER_REGISTER = '/user/register'
export const AUTH_USER_RECOVERY = '/user/recovery'
export const AUTH_USER_RECOVERY_PASSWORD = '/user/recovery/password'

export const MFA = '/mfa'
export const MFA_SETUP = '/mfa/setup'
export const MFA_CONFIRM = '/mfa/setup/confirm'
export const MFA_REVOKE = '/mfa/revoke'

export const SESSION_REFRESH = '/oauth/token'

// user
export const USER = '/user'
export const USER_ME = '/user/me'
export const USER_PASSSWORD = '/user/password'
export const USER_EMAIL_RESEND = '/user/email/resend'
export const USER_ACTIVITY_LOG = '/user/activity-log'

// bookmarks
export const BOOKMARKS = '/bookmarks'
export const COLLECTIONS = '/collections'

// features
export const FEATURES = 'features'

// version
export const VERSION = 'version'
