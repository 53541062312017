<template>
  <v-overlay
    :value="showUpdateAppOverlay" fixed z-index="99"
    color="neutral25" opacity="1" class="loader-overlay"
  >
    <v-container class="sheet-container py-0" :style="{'--maxWidth': `${maxWidthDesktop}px`}">
      <v-card flat class="subpage-content mt-md-6 py-md-8 mb-6">
        <v-container class="max-app-width full-height px-4 pt-12 pt-md-3">
          <v-row>
            <v-col cols="12" class="pt-0 pb-2">
              <h2 class="text-h5 font-weight-semibold" v-text="$t('overlay.update.title')" />
              <p class="text-body-1 neutral600--text" v-text="$t('overlay.update.description')" />
            </v-col>

            <v-col cols="12">
              <BrandButton
                variant="secondary" large block link
                target="_blank"
                rel="noopener noreferrer"
                :href="links.news"
              >
                {{ $t('common.update.what_is_new') }}
              </BrandButton>
            </v-col>

            <v-col cols="12">
              <BrandButton
                :variant="!(isExtension || isExtensionPopup) ? 'secondary' : 'primary'" large block link
                target="_blank"
                rel="noopener noreferrer"
                :href="links.howTo"
              >
                {{ $t('common.update.how_to_update') }}
              </BrandButton>
            </v-col>

            <v-col v-if="!(isExtension || isExtensionPopup)" cols="12">
              <BrandButton
                variant="primary" large block link
                target="_blank"
                rel="noopener noreferrer"
                :href="links.updateNow"
              >
                {{ $t('common.update.update_now') }}
              </BrandButton>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceAware from '@/mixins/DeviceAware'
import { HOW_TO_UPDATE_APP_LINKS, UPDATE_APP_LINKS, UPDATE_NEWS_LINKS } from '@/utils/constants'

export default {
  mixins: [DeviceAware],
  props: {
    maxWidthDesktop: {
      type: Number,
      default: 720,
    },
  },
  computed: {
    ...mapGetters({
      showUpdateAppOverlay: 'device/showUpdateAppOverlay',
    }),
    links() {
      const { locale } = this.$i18n
      const { deviceInfo } = this

      const linksObj = {
        news: UPDATE_NEWS_LINKS[locale],
        howTo: '',
        updateNow: '',
      }

      const browser = deviceInfo.browser?.toLowerCase()

      if (browser && (this.isDesktop || this.isExtension || this.isExtensionPopup)) {
        if (browser.includes('chrome')) {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.chrome[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.chrome
        }
        if (browser.includes('firefox')) {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.firefox[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.firefox
        }
        if (browser.includes('edge')) {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.edge[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.edge
        }
        if (browser.includes('safari')) {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.safari[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.safari
        }
      }

      if (this.isMobile) {
        if (deviceInfo.os === 'ios') {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.ios[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.ios
        }
        if (deviceInfo.os === 'android') {
          linksObj.howTo = HOW_TO_UPDATE_APP_LINKS.android[locale]
          linksObj.updateNow = UPDATE_APP_LINKS.android
        }
      }

      return linksObj
    },
  },
}
</script>

<style lang="scss" scoped>
.subpage-content {
  border-color: var(--v-neutral50-base) !important;
  background-color: var(--v-neutral0-base);
}

.sheet-container {
  max-width: var(--maxWidth);
}
</style>
