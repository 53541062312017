<template>
  <component
    :is="component"
    ref="comp"
    v-model="modelValue"
    v-bind="componentProps"
    v-on="$listeners"
  >
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>

    <v-card color="neutral25" flat class="rounded-lg">
      <slot v-if="!isDesktop" name="sheet-prepend">
        <h4
          v-if="sheetTitle"
          class="text--text text-h5 font-weight-semibold px-4 py-3"
          v-text="sheetTitle"
        />
      </slot>
      <slot />
    </v-card>
  </component>
</template>

<script>
import { VDialog } from 'vuetify/lib'
import SyncBooleanModel from '@/mixins/SyncBooleanModel'
import BackButtonMixin from '@/mixins/BackButtonMixin'

export default {
  mixins: [SyncBooleanModel, BackButtonMixin],
  inheritAttrs: true,
  props: {
    value: Boolean,
    sheetTitle: {
      type: String,
      default: null,
    },
    dialogProps: {
      type: Object,
      default: null,
    },
    sheetProps: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    component() {
      if (this.isDesktop) return VDialog
      return 'bottom-sheet-draggable'
    },
    componentProps() {
      if (this.isDesktop) {
        return {
          ...this.dialogProps,
        }
      }

      return {
        inset: true,
        ...this.sheetProps,
      }
    },
  },
  watch: {
    value: {
      handler(open) {
        if (open) {
          this.setupBackButtonDetector()
        } else {
          this.destroyBackButtonDetector()
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.modelValue = false
    },
    onBackButtonPressed() {
      const { comp } = this.$refs

      if (comp.onClose) {
        comp.onClose()
      } else if (comp.onClickOutside) {
        comp.onClickOutside()
      } else if (!this.componentProps.persistent) {
        this.close()
      }
    },
  },
}
</script>
