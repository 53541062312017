<template>
  <transition
    v-bind="transitionProps"
    @after-enter="transitioning = false" @before-leave="transitioning = true"
  >
    <component
      v-bind="layoutProps"
      :is="layoutComponent"
      class="layout-component"
      :class="{'layout-transitioning': transitioning}"
    />
  </transition>
</template>

<script>
import { getRouteInheritedMeta } from '@/utils/helpers'

export default {
  name: 'Layout',
  data() {
    return {
      layout: null,
      transitionName: null,
      transitioning: false,
    }
  },
  computed: {
    layoutComponent() {
      if (!this.layout) return null
      return () => import(/* webpackChunkName: "layout-[request]" */ `@/layouts/${this.layout}.vue`)
    },
    layoutProps() {
      return getRouteInheritedMeta(this.$route).layoutProps
    },
    transitionProps() {
      if (this.transitionName) {
        return {
          name: this.transitionName,
          mode: 'out-in',
        }
      }

      // don't delay route switch with out-in
      return {}
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        const newLayout = this.getRouteLayout(to)
        if (this.layout !== newLayout) {
          this.layout = newLayout
        }

        // no layout transition on first route
        if (from) {
          this.transitionName = this.getLayoutTransition(to, from)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getRouteLayout(route) {
      const meta = getRouteInheritedMeta(route)

      if (typeof meta.layout === 'function') {
        return meta.layout(this)
      }

      return meta.layout ?? 'default'
    },
    getLayoutTransition(to) {
      let inheritedToTransition = null

      // NOTE do we not want to use the from route's layout transition
      // it probably makes more sense to use it as a leave transition rather than enter

      for (const r of to.matched) {
        if (r.meta.layoutTransition) inheritedToTransition = r.meta.layoutTransition
      }

      return inheritedToTransition ?? 't-fade'
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-component {
  top: 0;
  left: 0;
  width: 100%;

  // &.layout-transitioning {
  //   overflow: hidden;
  //   height: 100vh;

  //   &:deep(.route-page) {
  //     overflow: hidden;
  //     height: 100vh;
  //   }
  // }
}
</style>
