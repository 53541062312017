import CollectionShareLink from '@/store/models/collections/CollectionShareLink'
import { DEFAULT_DURATION, DEACTIVATION_DURATION } from '@/utils/constants'

export default {
  // share link
  async getShareLink(_, collectionId) {
    const shareLink = await this.$api.collection.shareLink(collectionId)

    await CollectionShareLink.insertOrUpdate({
      data: {
        ...shareLink,
        collectionId: shareLink.of,
      },
    })

    return shareLink
  },
  async createShareLink(_, collectionId) {
    const token = await this.$api.collection.createShareLink(collectionId, {
      duration: DEFAULT_DURATION,
    })

    return token
  },
  async editShareLink(_, { collectionId, shareLinkId, data }) {
    const result = await this.$api.collection.editShareLink(collectionId, shareLinkId, data)
    await CollectionShareLink.update({
      where: shareLinkId,
      data: result,
    })

    return result
  },
  async deactivateShareLink(_, { collectionId, shareLinkId }) {
    const result = await this.$api.collection.editShareLink(collectionId, shareLinkId, {
      duration: DEACTIVATION_DURATION,
    })

    await CollectionShareLink.update({
      where: shareLinkId,
      data: result,
    })

    return result
  },
  async deleteShareLink(_, { collectionId, shareLinkId }) {
    await this.$api.collection.removeShareLink(collectionId, shareLinkId)
    await CollectionShareLink.delete(shareLinkId)
  },
  // email
  async inviteEmails(_, { collectionId, emails }) {
    return this.$api.collection.inviteEmails(collectionId, {
      invites: emails,
    })
  },
}
