import ActivityLog from '@/store/models/user/ActivityLog'

// NOTE should probrably move to user store
export default {
  async getActivityLog(_, params) {
    const { results, next } = await this.$api.user.activityLogGet({
      params: {
        sorting: 'createdAt',
        asc: false,
        ...params,
      },
    })

    await ActivityLog.insertOrUpdate({ data: results })

    return { results, next }
  },
  async exportActivityLog(_, { email }) {
    return this.$api.user.activityLogExport({
      ...(email && { email }),
    })
  },
}
