/* eslint-disable import/no-cycle */
import BaseModel from '@/utils/store/BaseModel'
import User from '@/store/models/user/User'
import Collection from '@/store/models/collections/Collection'
import { formatDateRelativeNow } from '@/utils/date-format'

class CollectionShareLink extends BaseModel {
  static entity = 'collectionsharelinks'

  static fields() {
    return {
      ...super.fields(),
      collectionId: this.string(),
      token: this.string(),
      expiresAt: this.attr(),
      // relationships
      createdById: this.string(),
      createdBy: this.belongsTo(User, 'createdById'),
      collection: this.belongsTo(Collection, 'collectionId'),
    }
  }

  get expirationFormatted() {
    return locale => {
      if (!this.expiresAt) return null
      return formatDateRelativeNow(new Date(this.expiresAt), {
        lang: locale,
        addSuffix: true,
      })
    }
  }

  get expired() {
    return new Date(this.expiresAt) < new Date()
  }

  get neverExpires() {
    // check if link is set to never expire
    return new Date(this.expiresAt) > new Date(2100, 0, 1)
  }

  get url() {
    const { collection } = this
    if (!collection) return null

    return `${CONFIG.appUrl}/collections/${collection.id}/invite-link/${this.token}`
  }
}

export default CollectionShareLink
