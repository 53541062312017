/* eslint-disable import/no-cycle */
import BaseModel from '@/utils/store/BaseModel'
import Collection from '@/store/models/collections/Collection'
import CollectionMember from '@/store/models/collections/CollectionMember'

class User extends BaseModel {
  static entity = 'users'

  static fields() {
    return {
      ...super.fields(),
      username: this.string().nullable(),
      email: this.string().nullable(),
      avatar: this.string().nullable(),
      deviceId: this.string().nullable(),
      createdAt: this.string().nullable(),
      importCredits: this.number(),
      //
      emailVerified: this.boolean(),
      verified: this.boolean(),
      mfaActive: this.boolean(),
      hasPassword: this.boolean(),
      collectionId: this.string(),
      // relationships
      collections: this.belongsToMany(Collection, CollectionMember, 'userId', 'collectionId'),
    }
  }

  get isDeviceAccount() {
    return !!this.deviceId && !this.email
  }

  get isFullAccount() {
    return !this.isDeviceAccount
  }

  get isVerifiedAccount() {
    return this.verified
  }

  get avatarUrl() {
    if (!this.avatar) return null
    return `${CONFIG.cdnUrl}/${this.avatar}?class=avatar`
  }
}

export default User
