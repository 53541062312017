<template>
  <div
    class="custom-checkbox"
    :class="{ 'custom-checkbox-checked': checked }"
  >
    <div
      class="custom-checkbox-inner"
      :class="{ 'custom-checkbox-inner-checked': checked }"
    >
      <v-icon v-if="checked" class="check-icon">
        mdi-check
      </v-icon>
    </div>
  </div>
</template>

<script>
// INFO: Virtual scroll package does not work well with input, img, link (a) tags.
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid var(--v-primary-base);
  border-radius: 4px;
  cursor: pointer;
  background: var(--v-neutral0-base);
}

.theme--light .custom-checkbox {
  border-color: black;
}

.theme--dark .custom-checkbox {
  border-color: white;
}

.custom-checkbox-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 20px !important;
  height: 20px !important;
  width: 100%;
  height: 100%;
}

.custom-checkbox-inner-checked {
  background-color: var(--v-teal600-base);
  border-color: var(--v-teal600-base);
}
.custom-checkbox-checked {
  border-color: var(--v-teal600-base) !important;
}

.v-icon {
  font-size: 20px;
}

.theme--light .check-icon {
  color: white !important;
}

.theme--dark .check-icon {
  color: black !important;
}
</style>
