import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMSearch from '@vuex-orm/plugin-search'

import * as api from '@/api'
import database from '@/store/database'
import bus from '@/plugins/eventbus'
import modules from '@/store/modules'

Vue.use(Vuex)
VuexORM.use(VuexORMSearch)

const store = new Vuex.Store({
  modules,
  plugins: [VuexORM.install(database)],
  strict: process.env.NODE_ENV !== 'production',
  actions: {
    resetApp({ dispatch, commit }) {
      // Delete entire vuex data
      dispatch('entities/deleteAll')

      commit('bookmarks/reset')
      commit('collections/resetState')
    },
  },
})

store.$api = api
store.$bus = bus

export default store
