<template>
  <v-app :style="styles">
    <v-system-bar
      v-if="$safeArea.top"
      v-bind="systemBarProps"
      fixed app
    />

    <v-overlay
      :value="!isOnline || userId === undefined" fixed z-index="99"
      color="neutral0" opacity="1" class="loader-overlay"
    >
      <div class="d-flex flex-column align-center justify-center full-height neutral900--text">
        <v-spacer />
        <v-responsive :aspect-ratio="92/28" max-width="164">
          <component
            :is="require('@/assets/img/logo.svg?inline')"
            class="full-width"
          />
        </v-responsive>
        <v-spacer />

        <v-progress-circular v-if="isOnline" color="blue50" indeterminate size="48" />
        <template v-else>
          <span v-text="$t('common.NO_NETWORK')" />
          <BrandButton class="mt-3" @click="onNetworkRetry">
            {{ $t('cta.try_again') }}
          </BrandButton>
        </template>
        <v-spacer />
      </div>
    </v-overlay>

    <UpdateApplicationOverlay />

    <v-overlay
      :value="isUnderMaintenance" fixed z-index="99"
      color="neutral0" opacity="1" class="loader-overlay"
    >
      <div class="d-flex flex-column align-center justify-center full-height neutral900--text">
        <v-spacer />
        <v-responsive :aspect-ratio="92/28" max-width="164">
          <component
            :is="require('@/assets/img/logo.svg?inline')"
            class="full-width"
          />
        </v-responsive>
        <h3 class="text-sm-h3 text-h4 text-center gradient-text font-weight-black">
          {{ $t('under_maintenance.title') }}
        </h3>
        <p class="release-notification-text text-body-1 text-center mt-3">
          {{ $t('under_maintenance.description') }}
        </p>
        <BrandButton
          variant="primary" x-large class="mt-8" :href="updateNewsLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('under_maintenance.cta') }}
        </BrandButton>
        <v-spacer />
      </div>
    </v-overlay>

    <ToastFactory />
    <ModalFactory />

    <ExtensionMini v-if="ready && isExtensionMini" />
    <Layout v-if="ready && !isExtensionMini" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SplashScreen } from '@capacitor/splash-screen'
import ToastFactory from '@/components/factories/ToastFactory'
import ModalFactory from '@/components/factories/ModalFactory'
import UpdateApplicationOverlay from '@/components/overlays/UpdateApplicationOverlay'
import ExtensionMini from '@/ExtensionMini'
import { UPDATE_NEWS_LINKS } from '@/utils/constants'

export default {
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      titleTemplate: chunk => this.$t(chunk ? 'page.default.meta.title_template' : 'page.default.meta.title', [chunk]),
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('page.default.meta.description'),
      }],
    }
  },
  components: {
    ExtensionMini, ToastFactory, ModalFactory, UpdateApplicationOverlay,
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
      isOnline: 'device/online',
      isExtensionMini: 'device/isExtensionMini',
      localeLoaded: 'device/localeLoaded',
      isUnderMaintenance: 'features/UnderMaintenance',
    }),
    ready() {
      return this.localeLoaded && this.isOnline
    },
    styles() {
      const {
        top, left, right, bottom,
      } = this.$vuetify.application

      return {
        '--v-app-top': `${top}px`,
        '--v-app-right': `${right}px`,
        '--v-app-left': `${left}px`,
        '--v-app-bottom': `${bottom}px`,
      }
    },
    systemBarProps() {
      const parentRoute = this.$route.matched[0]

      return {
        height: this.$safeArea.top,
        ...parentRoute?.meta?.systemBar,
        ...this.$route?.meta?.systemBar,
      }
    },
    updateNewsLink() {
      return UPDATE_NEWS_LINKS[this.$i18n.locale]
    },
  },
  watch: {
    async ready(val) {
      if (val && this.$isNative) {
        await SplashScreen.hide()
      }
    },
  },
  methods: {
    ...mapActions({
      checkDeviceConnectivity: 'device/checkDeviceConnectivity',
    }),
    onNetworkRetry() {
      this.checkDeviceConnectivity().catch(() => {
        this.$toast.error(this.$t('common.NO_NETWORK'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-system-bar--fixed {
  z-index: 99999;
}

.loader-overlay:deep(.v-overlay__content) {
  height: 100%;
}

.gradient-text {
  background:
      linear-gradient(
        to right,
        var(--v-blue400-base),
        var(--v-teal400-base)
      );
    background-clip: text;
    color: transparent;
}

.release-notification-text {
  max-width: 500px;
}
</style>
