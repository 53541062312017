<template>
  <component :is="icon" class="brand-icon" />
</template>

<script>
const ICONS = {
  facebook: () => import('@/assets/img/brands/facebook.svg?inline'),
  google: () => import('@/assets/img/brands/google.svg?inline'),
  apple: () => import('@/assets/img/brands/apple.svg?inline'),
}

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return ICONS[this.name]
    },
  },
}
</script>

<style lang="scss" scoped>
svg.brand-icon {
  width: 1em;
  height: 1em;
}
</style>
