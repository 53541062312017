import CollectionMember from '@/store/models/collections/CollectionMember'

export default {
  // members
  async getCollectionMembers(_, { collectionId, filters }) {
    const { results, next } = await this.$api.collection.members(collectionId, { params: filters })
    await CollectionMember.insertOrUpdate({ data: results })

    return { results, next }
  },
  // join
  async joinViaShareLink(_, { collectionId, token }) {
    return this.$api.collection.acceptShareLink(collectionId, { token })
  },
  async acceptEmailInvite(_, { collectionId, token }) {
    return this.$api.collection.acceptInvite(collectionId, { token })
  },
  async getShareLinkInfo(_, { collectionId, token }) {
    return this.$api.collection.shareLinkInfo(collectionId, { token })
  },
  async getInviteInfo(_, { collectionId, token }) {
    return this.$api.collection.inviteInfo(collectionId, { token })
  },
  // leave
  async removeMember(_, { collectionId, memberId }) {
    await this.$api.collection.removeMember(collectionId, memberId)
    await CollectionMember.delete(memberId)
  },
}
