<template>
  <v-autocomplete class="brand-autocomplete-field" v-bind="{ ...commonAttrs, ...$attrs }" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-autocomplete>
</template>

<script>
const VARIANTS = {
  basic: {
    outlined: true,
    backgroundColor: 'neutral0',
    color: 'blue400',
    classes: ['brand-autocomplete-field--basic', 'rounded-lg'],
  },
}
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    commonAttrs() {
      const variant = VARIANTS[this.variant]
      const classes = []
      const common = {
        hideDetails: 'auto',
        menuProps: {
          rounded: 'lg',
          nudgeBottom: 8,
        },
      }

      return {
        ...common,
        ...variant,
        class: [
          ...classes,
          ...(variant?.classes ?? []),
        ],
      }
    },
  },
  methods: {
    focus() {
      this.$children[0]?.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-autocomplete.brand-autocomplete-field {
  &.brand-autocomplete-field--basic:not(.v-input--is-disabled) {
    &.v-text-field--outlined:not(.v-input--has-state):not(.v-input--is-focused):deep(fieldset) {
      border-color: var(--v-neutral50-base);
    }
  }
}

</style>
