import BaseModel from '@/utils/store/BaseModel'

class LoadingModel extends BaseModel {
  static fields() {
    return {
      ...super.fields(),
      loading: this.attr({}),
    }
  }

  async setLoading(key, val) {
    return this.constructor.update({
      where: this.id,
      data(entity) {
        const loading = { ...entity.loading }
        if (val) {
          loading[key] = val
        } else {
          delete loading[key]
        }

        // eslint-disable-next-line no-param-reassign
        entity.loading = loading
      },
    })
  }
}

export default LoadingModel
