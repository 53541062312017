<template>
  <v-card flat color="transparent" class="bookmark-item--list">
    <div class="d-flex align-start">
      <!-- <v-img
        :src="bookmark.faviconUrl"
        :aspect-ratio="1" :width="$vuetify.breakpoint.mdAndUp ? 32 : 24"
        class="neutral50 flex-grow-0 rounded"
      /> -->
      <BulkSelectCheckbox
        v-if="isBulkActionsActive"
        class="mx-3 mt-0"
        :checked="isSelected"
        @click.native="toggleSelect"
      />
      <!-- FIXME v-img cannot be empty or it flickers/re-renders component weirdly -->
      <!-- probably move away from v-img entirely for anything inside virtual scroller views cuz of inters. obser. -->
      <!-- Can just style the v-responsive div with background-url and size cover -->
      <v-img
        :src="bookmark.faviconUrl" :aspect-ratio="1" :width="$vuetify.breakpoint.mdAndUp ? 32 : 24"
        class="flex-grow-0 rounded" contain
        :class="bookmark.hasFavicon ? 'neutral25' : bookmark.colorClasses"
      />

      <div class="card-content flex-grow-1 of-hidden px-4">
        <div class="neutral400--text d-flex align-center mb-2 mt-md-1 text-caption text-md-body-2">
          <BookmarkSource :bookmark="bookmark" no-icon />
          <span class="mx-1">&middot;</span>
          <BookmarkDate :value="bookmark.createdAt" />
        </div>

        <a
          :href="bookmark.brandedUrl" target="_blank" rel="noopener"
          class="neutral900--text" :title="bookmark.title"
          @click="onLinkClick"
        >
          <h4
            class="bookmark-headline text-body-1 mb-1 font-weight-semibold"
          >
            <v-progress-circular
              v-if="bookmark.isLoading && !bookmark.hasSufficientData"
              indeterminate size="16" color="blue400" width="3"
              class="mr-2"
            />
            <span v-text="bookmark.title ?? bookmark.domain" />
          </h4>

          <p
            v-if="bookmark.description"
            class="bookmark-description text-body-2 neutral600--text mb-3"
            v-text="bookmark.description"
          />
        </a>

        <BookmarkTags v-if="bookmark.tags.length" class="mb-3" :tags="bookmark.tags" v-on="$listeners" />

        <BookmarkActions
          :bookmark="bookmark" :user-id="userId"
          class="text--text" v-on="$listeners"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import BookmarkSource from './BookmarkSource'
import BookmarkActions from './BookmarkActions'
import BookmarkDate from './BookmarkDate'
import BookmarkTags from './BookmarkTags'
import BulkActionsMixin from '../mixins/BulkActions'

export default {
  components: {
    BookmarkActions,
    BookmarkTags,
    BookmarkDate,
    BookmarkSource,
  },
  mixins: [BulkActionsMixin],
  props: {
    bookmark: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    isSelected() {
      return this.bookmark.isSelected
    },

  },
  methods: {
    onLinkClick(event) {
      if (this.isBulkActionsActive) {
        event.preventDefault()
        this.toggleSelect()
      }
    },
    toggleSelect() {
      this.$emit('toggle-select', this.bookmark)
    },
  },
}
</script>

<style lang="scss" scoped>
.bookmark-headline {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

.bookmark-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}
</style>
