import { SendIntent } from 'send-intent'
import { Capacitor } from '@capacitor/core'
import EventBus from './eventbus'
import router from './router'

const URL_RE = /(https?:\/\/[^\s]+)/g

function extractTitle(titleString) {
  if (!titleString) return null
  const title = decodeURIComponent(titleString)

  // if we find a URL, delete it
  const titleWithoutUrls = title.replaceAll(URL_RE, '').trim()

  return titleWithoutUrls || null
}

function extractUrlFromIntent(intent) {
  if (intent.url) return intent.url
  if (intent.additionalItems?.[0]?.url) return intent.additionalItems[0].url

  const title = intent.title || intent.additionalItems?.[0]?.title
  if (!title) return null

  const decodedUrl = decodeURIComponent(title)

  // look for URL in title
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const url = decodedUrl.match(urlRegex)?.[0]

  return url || null
}

async function checkIntent(vm) {
  console.log('Checking Intent')

  try {
    const result = await SendIntent.checkSendIntentReceived()

    if (!result) {
      console.error('No SendIntent found')
      return
    }

    console.log('SendIntent found', JSON.stringify(result))

    const payload = result.url ? result : result.additionalItems?.[0]
    let url = extractUrlFromIntent(result)

    if (!url) {
      console.error('No URL found in intent')
      return
    }

    try {
      url = new URL(decodeURIComponent(url))
    } catch (error) {
      console.error('Error resolving URL', error, error.message)
      return
    }

    if (url) {
      const parsedData = {
        ...payload,
        title: extractTitle(payload?.title || result.title)?.slice(0, 100) ?? undefined, // TODO 100 should be a const
        url: url.toString(),
      }

      vm.$store.dispatch('native/onNewIntent', {
        url: parsedData.url,
        result: parsedData,
        finish: () => {
          if (Capacitor.getPlatform() === 'android') {
            SendIntent.finish()
          }
        },
      })

      router.replace('/bookmarks/add').catch(() => {})
    }
  } catch (error) {
    console.error('Error handing sendIntent:', error, error.message)
  }
}

EventBus.$once('app:created', async vm => {
  if (vm.$isNative) {
    checkIntent(vm)

    window.addEventListener('sendIntentReceived', async () => {
      console.log('sendIntent received')
      checkIntent(vm)
    })
  }
})
