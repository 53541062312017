<template>
  <v-btn v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
    <BrandIcon class="text-h5 mr-2" name="facebook" />
    <slot />
  </v-btn>
</template>

<script>
import BrandIcon from './BrandIcon.vue'

export default {
  components: { BrandIcon },
  inheritAttrs: false,
  computed: {
    commonAttrs() {
      return {
        color: '#1877F2',
        class: [
          'text-none white--text',
          'rounded-lg',
        ],
      }
    },
  },
}
</script>
