/* eslint-disable import/no-cycle */
import LoadingModel from '@/utils/store/LoadingModel'

const SHAPES = ['triangle', 'asterisk', 'foursquares', 'snowflake']
const COLORS = [
  ['teal200--text', 'teal25'],
  ['mint200--text', 'mint25'],
  ['aquamarine200--text', 'aquamarine25'],
  ['orange200--text', 'orange25'],
  ['blue200--text', 'blue25'],
  ['red200--text', 'red25'],
]

class TestModel extends LoadingModel {
  static entity = 'tests'

  static fields() {
    return {
      ...super.fields(),
      index: this.number(),
      favorite: this.boolean(false),
      text: this.string(),
      rand: this.attr(Math.random),
    }
  }

  get thumbnailUrl() {
    return this.rand < 0.2 ? null : `https://api.lorem.space/image?hash=${this.rand}`
    // return `https://api.lorem.space/image?hash=${this.rand}`
  }

  get faviconUrl() {
    // return this.rand < 0.2 ? null : `https://api.lorem.space/image?hash=${this.rand}`
    return `https://api.lorem.space/image?hash=${this.rand}`
  }

  get colorClasses() {
    return COLORS[Math.floor(this.rand * COLORS.length)]
  }

  get shape() {
    return SHAPES[Math.floor(this.rand * SHAPES.length)]
  }
}

export default TestModel
