import VueRouter from 'vue-router'
import { App } from '@capacitor/app'

import EventBus from '@/plugins/eventbus'
import { stateGetters, stateSetters } from '@/utils/store/store-utils'
import router from '@/plugins/router'

const { isNavigationFailure, NavigationFailureType } = VueRouter

const defaultState = {
  intent: null,
  backButtonHandler: null,
}

export default {
  name: 'native',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    clearBackButtonHandler({ getters, commit }) {
      getters.backButtonHandler?.destroy()
      commit('setBackButtonHandler', null)
    },
    setupBackButtonListener({ commit, dispatch }) {
      dispatch('clearBackButtonHandler')

      // TODO make abstraction layer over this and let listeners subscribe to it
      // (because there's no way to check if there are other listeners here)
      // ionic/vue has a priority system, might be an option but maybe overkill
      const handler = App.addListener('backButton', event => {
        console.log('Native back button detected')
        dispatch('onBackButtonPressed', event)
      })

      commit('setBackButtonHandler', handler)
    },
    onBackButtonPressed({ getters, commit }, event) {
      // default behaviour
      if (event.canGoBack) {
        router.back()
        return
      }

      // // go back to homepage before exiting (not standard practice for android apps)
      // if (router.currentRoute.path !== '/bookmarks') {
      //   router.replace('/bookmarks')
      //   return
      // }

      // close via active intent
      if (getters.intent) {
        console.log('Exiting via intent')

        getters.intent.finish()
        commit('setIntent', null)
        return
      }

      App.exitApp()
    },
    //
    onNewIntent({ commit }, intent) {
      // Doesn't need to do anything else for now
      commit('setIntent', intent)
    },
  },
}

EventBus.$once('app:created', async vm => {
  if (vm.$isNative) {
    vm.$store.dispatch('native/setupBackButtonListener')

    // handle deeplinks
    // NOTE this has buggy behaviour when running with `yarn dev:android`
    // probably because the app is never really killed, building app via Android Studio shows normal behaviour

    App.addListener('appUrlOpen', event => {
      console.log('App was opened from external url', event.url)
      const url = new URL(event.url) // this also works with app url schemes, ex. com.rubra.appclient

      if (!url.pathname) {
        console.warn('No pathname found in url, ignoring...')
        return
      }

      router.push(url.pathname).catch(err => {
        // Only duplicated navigations
        if (isNavigationFailure(err, NavigationFailureType.duplicated)) {
          vm.$bus.$emit('router:error:duplicated')
        } else {
          console.error(err)
        }
      })
    })
  }
})
