import BaseModel from '@/utils/store/BaseModel'
import User from '@/store/models/user/User'

class UserComment extends BaseModel {
  static entity = 'usercomments'

  static fields() {
    return {
      ...super.fields(),
      text: this.string(),
      resourceType: this.attr(),
      createdAt: this.attr().nullable(),
      // relationships
      userId: this.attr(null),
      user: this.belongsTo(User, 'userId'),
      resourceId: this.attr(null),
      resource: this.morphTo('resourceId', 'resourceType'),
    }
  }
}

export default UserComment
