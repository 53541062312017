import { Model } from '@vuex-orm/core'

class BaseModel extends Model {
  static primaryKey = '_id'

  static fields() {
    return {
      _id: this.string(),
    }
  }

  get id() {
    return this.$id
  }
}

export default BaseModel
