import AuthSession from '@/store/models/user/AuthSession'

export default {
  async getActiveSessions(_, params) {
    const { results, next } = await this.$api.auth.activeSessions({
      params: {
        sorting: 'createdAt',
        asc: false,
        ...params,
      },
    })

    await AuthSession.insertOrUpdate({ data: results })

    return { results, next }
  },
  async invalidateSession(_, session) {
    await this.$api.auth.invalidateSession(session.sessionId)
    session.$delete()
  },
}
