<template>
  <span>
    {{ new Date(value).toLocaleDateString() }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
