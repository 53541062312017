import { format, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { enGB, de } from 'date-fns/locale'

const LANGS = {
  en: enGB,
  de,
}

export function formatDate(date, formatStr, lang) {
  return format(date, formatStr ?? 'PPPP', {
    locale: LANGS[lang] ?? LANGS.en,
  })
}

export function formatDateRelativeNowStrict(date, opts = {}) {
  return formatDistanceToNowStrict(date, {
    ...opts,
    locale: LANGS[opts.lang] ?? LANGS.en,
  })
}

export function formatDateRelativeNow(date, opts = {}) {
  return formatDistanceToNow(date, {
    ...opts,
    locale: LANGS[opts.lang] ?? LANGS.en,
  })
}
