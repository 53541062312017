export default [
  {
    path: '/collections',
    components: {
      default: () => import(/* webpackChunkName: "col-all" */ '@/views/Collections/Collections.vue'),
      appbar: () => import(/* webpackChunkName: "bm-appbar" */ '@/views/Bookmarks/components/BookmarkAppBar.vue'),
      footer: () => import(/* webpackChunkName: "footer" */ '@/components/AppFooterExpandable.vue'),
    },
    props: {
      footer: {
        expandable: true,
      },
    },
    meta: {
      appbarVisibility: true,
      requiresAuth: true,
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  // Create
  {
    path: '/collections/add',
    component: () => import(/* webpackChunkName: "col-add" */ '@/views/Collections/CreateCollection.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  // View bookmarks, order matters
  {
    path: '/collections/:id',
    components: {
      default: () => import(/* webpackChunkName: "col-id" */ '@/views/Collections/Collection.vue'),
      sidebar: () => import(/* webpackChunkName: "col-sidebar" */ '@/views/Collections/components/CollectionSidebar.vue'),
      appbar: () => import(/* webpackChunkName: "col-appbar" */ '@/views/Collections/components/CollectionAppBar.vue'),
      footer: () => import(/* webpackChunkName: "footer" */ '@/components/AppFooterExpandable.vue'),
    },
    props: {
      footer: {
        expandable: true,
      },
    },
    meta: {
      sidebarVisibility() {
        return true
      },
      appbarVisibility: true,
      requiresAuth: true,
      transition: 't-fade',
      layoutTransition: 't-expand',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  // Edit
  {
    path: '/collections/:id/edit',
    component: () => import(/* webpackChunkName: "col-edit" */ '@/views/Collections/EditCollection.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      layoutProps: {
        backButton: {
          // invite pages redirect to this, we don't want to use regular back button
          to: from => `/collections/${from.params.id}`,
        },
      },
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/bulk/edit-share-link',
    component: () => import(/* webpackChunkName: "col-edit-link" */ '@/views/Collections/BulkEditShareLink.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/edit-share-link',
    component: () => import(/* webpackChunkName: "col-edit-link" */ '@/views/Collections/EditShareLink.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/bulk/invite',
    component: () => import(/* webpackChunkName: "col-invite" */ '@/views/Collections/BulkInviteMembers.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/invite',
    component: () => import(/* webpackChunkName: "col-invite" */ '@/views/Collections/InviteMembers.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  // Join
  {
    path: '/collections/:id/invite-link/:token',
    component: () => import(/* webpackChunkName: "col-share-accept" */ '@/views/Collections/ShareLinkJoin.vue'),
    meta: {
      requiresAuth: true,
      layout: 'fullscreen',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/invite-email/:token',
    component: () => import(/* webpackChunkName: "col-invite-accept" */ '@/views/Collections/InviteAccept.vue'),
    meta: {
      requiresAuth: true,
      layout: 'fullscreen',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  // bookmarks
  {
    path: '/collections/:id/bookmarks/:bookmarkId/comments',
    component: () => import(/* webpackChunkName: "col-bm-comments" */ '@/views/Bookmarks/Comments.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
      layoutProps: {
        backButton: {
          // emails redirect to this, we don't want to use regular back button
          // TODO this is a really dirty system, it should be refactored
          to: from => `/collections/${from.params.id}`,
        },
      },
    },
  },
  {
    path: '/collections/:id/bulk/add-tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/BulkEditTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/bulk/remove-tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/BulkRemoveTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/bookmarks/:bookmarkId/likes',
    component: () => import(/* webpackChunkName: "col-bm-comments" */ '@/views/Bookmarks/Likes.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
      layoutProps: {
        backButton: {
          // emails redirect to this, we don't want to use regular back button
          // TODO this is a really dirty system, it should be refactored
          to: from => `/collections/${from.params.id}`,
        },
      },
    },
  },
  {
    path: '/collections/:id/bookmarks/:bookmarkId/tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/EditTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/collections/:id/bookmarks/:bookmarkId/edit',
    component: () => import(/* webpackChunkName: "bm-edit" */ '@/views/Bookmarks/Edit.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
]
