<template>
  <v-row justify="center">
    <v-col cols="12">
      <Illustration
        name="no-bookmarks"
        :max-width="240"
        width="60%"
        class="mx-auto mb-4"
      />
    </v-col>
    <v-col cols="12" class="text-center">
      <h2 class="text-h5 font-weight-semibold mb-3">
        {{ $t(filtersActive ? "common.filters.no_results.title" : "page.bookmarks.all.no_data.title") }}
      </h2>
      <p>
        <span v-if="filtersActive" v-text="$t('common.filters.no_results.text')" />
        <span v-else-if="isExtension" v-text="$t('page.bookmarks.all.no_data.text.extension')" />
        <span v-else v-text="$t('page.bookmarks.all.no_data.text')" />
      </p>
    </v-col>

    <v-col v-if="filtersActive" cols="12" sm="auto">
      <BrandButton
        x-large
        block
        width="250"
        variant="basic"
        @click="$emit('reset')"
      >
        {{ $t("cta.reset_filters") }}
      </BrandButton>
    </v-col>

    <template v-else>
      <v-col v-if="!isExtension" cols="12" sm="auto">
        <BrandButton
          x-large block width="250" variant="primary"
          to="/bookmarks/add" exact
        >
          {{ $t("cta.add_a_bookmark") }}
        </BrandButton>
      </v-col>
      <v-col cols="12" sm="auto">
        <BrandButton
          x-large
          block
          width="250"
          variant="basic"
          to="/account/settings/privacy/import"
        >
          {{ $tc("cta.import_bookmark", 2) }}
        </BrandButton>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import DeviceAware from '@/mixins/DeviceAware'

export default {
  mixins: [DeviceAware],
  props: {
    filtersActive: {
      type: [Boolean, Array],
      default: false,
    },
  },
}
</script>
