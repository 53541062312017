<template>
  <v-text-field class="brand-text-field" v-bind="{ ...commonAttrs, ...$attrs }" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<script>
const VARIANTS = {
  basic: {
    outlined: true,
    backgroundColor: 'neutral0',
    color: 'blue400',
    classes: ['brand-text-field--basic', 'rounded-lg'],
  },
  secondary: {
    solo: true,
    flat: true,
    backgroundColor: 'neutral30',
    color: 'text',
    classes: ['brand-text-field--secondary', 'rounded-lg'],
  },
}
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: null,
    },
    autoselect: Boolean,
  },
  computed: {
    commonAttrs() {
      const variant = VARIANTS[this.variant]
      const classes = []
      const common = {
        'hide-details': 'auto',
        'validate-on-blur': true,
      }

      return {
        ...common,
        ...variant,
        class: [
          ...classes,
          ...(variant?.classes ?? []),
        ],
      }
    },
  },
  mounted() {
    // same as autofocus but also selects the entire content
    if (this.autoselect) this.select()
  },
  methods: {
    focus() {
      this.$children[0]?.focus()
    },
    blur() {
      this.$children[0]?.blur()
    },
    validate() {
      this.$children[0]?.validate()
    },
    select() {
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/select#notes
      this.focus()

      this.$children[0]?.$refs.input?.select()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-text-field.brand-text-field {
  &.brand-text-field--basic {
    &:not(.v-input--is-disabled) {
      &.v-text-field--outlined:not(.v-input--has-state):not(.v-input--is-focused):deep(fieldset) {
        border-color: var(--v-neutral50-base);
      }
    }

    &.v-input--dense:deep(.v-input__append-inner) {
      margin-top: 6px;
    }

    &.v-input--dense:deep(.v-input__prepend-inner) {
      margin-top: 12px;
    }
  }
}

</style>
