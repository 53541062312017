import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'

import Vuetify from '@/plugins/vuetify'
import Preferences from '@/plugins/preferences'
import EventBus from '@/plugins/eventbus'
import { stateGetters, stateSetters, watchGetterAndPersist } from '@/utils/store/store-utils'
import { getDeviceColorSchemePreference } from '@/utils/helpers'
import { loadAsyncLocale } from '@/plugins/i18n'
import semver from 'semver'
import { addWeeks, isAfter } from 'date-fns'

const defaultState = {
  id: null,
  platform: null,
  os: null,
  browser: null,
  browserVersion: null,
  breakpoint: null,
  language: null,
  theme: 'auto',
  online: true,
  localeLoaded: false,
  appVersion: APPVERSION,
  latestAppVersion: APPVERSION,
  appVersionUpdatedAt: new Date(),
  forceUpdateLayout: false,
}

export default {
  name: 'device',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
    all(s) {
      return {
        id: s.id,
        platform: s.platform,
        os: s.os,
        browser: s.browser,
        browserVersion: s.browserVersion,
        language: s.language,
      }
    },
    showUpdateAppOverlay(s) {
      return semver.lt(s.appVersion, s.latestAppVersion) || s.forceUpdateLayout
    },
    showWhatIsNew(s) {
      const twoWeeksAfterRelease = addWeeks(s.appVersionUpdatedAt, 2)
      return !isAfter(new Date(), twoWeeksAfterRelease)
    },
    isMobile(s, g) {
      return !g.isDesktop
    },
    isDesktop(s, g) {
      if (!g.breakpoint) return true // HACK race condition with watcher below
      if (g.isNative && g.breakpoint?.smAndDown) return false

      return g.breakpoint?.mdAndUp || g.isExtensionPopup
    },
    isNative() {
      // return true // DEBUG
      return Capacitor.isNativePlatform()
    },
    isWeb(s, g) {
      // return true // DEBUG
      if (g.isExtension) return false
      return Capacitor.getPlatform() === 'web'
    },
    isExtension() {
      // return true // DEBUG
      return BUILD_TARGET === 'extension'
    },
    isExtensionPopup() {
      // return true // DEBUG
      return window.RUBRA_EXTENSION_ENV === 'popup'
    },
    isExtensionMini(s, g) {
      // return true // DEBUG
      return g.isExtensionPopup && EXT_MINI
    },
    isExtensionPopupMini() {
      return window.innerWidth < 500 && BUILD_TARGET === 'extension'
    },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    setTheme({ commit }, theme) {
      let isDark = theme === 'dark'

      if (theme === 'auto') {
        isDark = getDeviceColorSchemePreference() === 'dark'
      }

      Vuetify.framework.theme.dark = isDark
      commit('setTheme', theme)

      // for initial page load, write to sync storage
      localStorage.setItem('lastTheme', theme)
    },
    onOsThemeSwitch({ getters }, isDark) {
      const { theme } = getters
      if (theme !== 'auto') return

      Vuetify.framework.theme.dark = isDark
    },
    async loadLocale({ commit, getters }, locale) {
      await loadAsyncLocale(locale)

      if (!getters.localeLoaded) {
        commit('setLocaleLoaded', true)
      }
    },
    async checkDeviceConnectivity({ commit }) {
      const health = await this.$api.app.getHealth()
      commit('setOnline', !!health)
    },
    async checkAppVersion({ commit }) {
      const { minRequiredAppVersionUpdatedAt, minRequiredAppVersion } = await this.$api.version.fetchAppVersion()
      commit('setLatestAppVersion', minRequiredAppVersion)
      commit('setAppVersionUpdatedAt', new Date(minRequiredAppVersionUpdatedAt))
    },
  },
}

EventBus.$once('app:created', async vm => {
  vm.$store.dispatch('device/checkAppVersion').catch(console.error)

  // store device id in preferences for syncing with extension
  const deviceId = (await Preferences.get('deviceId')) ?? (await Device.getId()).identifier
  watchGetterAndPersist(vm.$store, 'device/id', 'deviceId')
  vm.$store.commit('device/setId', deviceId)

  const info = await Device.getInfo()

  if (BUILD_TARGET === 'extension') {
    info.platform = 'extension'
  }

  vm.$store.commit('device/setPlatform', info.platform)
  vm.$store.commit('device/setOs', info.operatingSystem)

  // if in web platform, get browser as well
  if (!Capacitor.isNativePlatform()) {
    const { default: Bowser } = await import('bowser')
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser()

    vm.$store.commit('device/setBrowser', browser.name)
    vm.$store.commit('device/setBrowserVersion', browser.version)
  }

  // put vuetify's relevant breakpoint data in store
  vm.$watch('$vuetify.breakpoint.name', () => {
    const { breakpoint } = vm.$vuetify
    vm.$store.commit('device/setBreakpoint', {
      name: breakpoint.name,
      width: breakpoint.width,
      height: breakpoint.height,
      mdAndUp: breakpoint.mdAndUp,
      smAndDown: breakpoint.smAndDown,
    })
  }, { immediate: true })

  // theme settings
  const theme = (await Preferences.get('theme')) ?? 'auto'
  vm.$store.dispatch('device/setTheme', theme)
  watchGetterAndPersist(vm.$store, 'device/theme', 'theme')

  // OS theme sync
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', ({ matches: isDark }) => {
      vm.$store.dispatch('device/onOsThemeSwitch', isDark)
    })

  // language sync
  vm.$watch('$i18n.locale', lang => vm.$store.commit('device/setLanguage', lang), { immediate: true })
  watchGetterAndPersist(vm.$store, 'device/language', 'lang')
})
