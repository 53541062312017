<template>
  <v-col cols="12">
    <v-row>
      <v-col>
        <BrandButton
          variant="secondary"
          color="neutral25 neutral900--text"
          class="mr-3"
          :class="$vnode.data.staticClass"
          v-bind="{...btnProps(false)}"
          @click="bulkToggleAllSelected"
        >
          <BulkSelectCheckbox
            v-if="isBulkActionsActive"
            class="mr-0 mt-0 pt-0"
            :checked="bulkIsAllSelected"
          />
          <span class="neutral600--text mr-1 ml-3">&#124;</span>
          <span v-text="$tc('bulk.action_bar.counter', numberOfSelectedItems)" />
        </BrandButton>
        <DropdownHybrid
          v-if="numberOfSelectedItems > 0"
          ref="dropdown"
          :sheet-title="$t('bulk.action_bar.options')"
          :menu-props="{right: true, minWidth: 240}"
        >
          <template #activator="{ on, attrs }">
            <BrandButton
              variant="secondary"
              color="neutral25 neutral900--text"
              :class="$vnode.data.staticClass"
              v-bind="{...attrs, ...btnProps()}"
              v-on="on"
            >
              <PhDotsThreeVertical class="text-h5" />
              <span class="ml-3 d-none d-md-inline" v-text="$t('bulk.action_bar.options')" />
            </BrandButton>
          </template>

          <v-list color="neutral25" class="py-3">
            <v-list-item v-for="option in options" :key="option.key" @click="onSelect(option.action)">
              <v-list-item-icon class="mr-3">
                <component :is="option.icon" class="text-h5" :class="option.color" :weight="option.weight" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :class="`${option.color} font-weight-medium`" v-text="$t(option.text)" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </DropdownHybrid>
      </v-col>
      <v-col cols="auto">
        <BrandButton
          variant="secondary"
          color="neutral25 neutral900--text"
          :class="$vnode.data.staticClass"
          v-bind="{...btnProps()}"
          @click="toggleBulkActions"
        >
          <PhX class="text-h5" />
          <span class="ml-3 d-none d-md-inline" v-text="$t('bulk.action_bar.exit')" />
        </BrandButton>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {
  PhX,
  PhDotsThreeVertical,
} from 'phosphor-vue'

import BulkActionsMixin from '../mixins/BulkActions'

// TODO: instead of isCollection, isDeleted make the number of items as prop
export default {
  components: {
    PhX,
    PhDotsThreeVertical,
  },
  mixins: [BulkActionsMixin],
  props: {
    options: {
      required: true,
      type: Array,
    },
    isCollection: {
      required: false,
      type: Boolean,
      default: false,
    },
    isDeleted: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numberOfSelectedItems() {
      if (this.isCollection) {
        return this.numberOfCollections
      }
      if (this.isDeleted && this.bulkIsAllSelected) {
        return this.numberOfDeletedBookmarks
      }
      return this.bulkNumberOfBookmarks
    },
  },
  methods: {
    btnProps(collapse = true) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return { rounded: true, xLarge: true }
      }

      if (!collapse) {
        return { height: 48, rounded: true }
      }

      return { height: 48, width: 48, fab: true }
    },
    onSelect(cb) {
      this.$refs.dropdown?.close()
      cb()
    },
  },
}
</script>
