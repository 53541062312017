/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-cycle */
import seed from 'seed-random'
import { Share } from '@capacitor/share'
import LoadingModel from '@/utils/store/LoadingModel'
import BookmarkTag from '@/store/models/bookmarks/BookmarkTag'
import User from '@/store/models/user/User'
import Collection from '@/store/models/collections/Collection'

export const SHOW_COUNT = 25

export const SHAPES = ['1', '2', '3', '4', '5']
export const COLORS = ['thumbnailsteal', 'thumbnailsmint', 'thumbnailsaquamarine', 'thumbnailsorange', 'thumbnailsblue', 'thumbnailsred']

class Bookmark extends LoadingModel {
  static entity = 'bookmarks'

  static fields() {
    return {
      ...super.fields(),
      title: this.string(),
      url: this.string(),
      likes: this.number(0),
      // meta fields
      description: this.string().nullable(),
      image: this.string().nullable(),
      favicon: this.string().nullable(),
      language: this.string().nullable(),
      readTime: this.number(0).nullable(),
      // flags
      isPrivate: this.boolean(false),
      isFavorite: this.boolean(false),
      isLoading: this.boolean(false),
      isLikedByUser: this.boolean(false),
      searchScore: this.number(0),
      deleted: this.boolean(false),
      isSelected: this.boolean(false),
      // dates
      createdAt: this.attr(),
      updatedAt: this.attr(),
      deletedAt: this.attr().nullable(),
      // relationships
      addedById: this.attr(null),
      addedBy: this.belongsTo(User, 'addedById'),
      ownerId: this.attr(null),
      owner: this.belongsTo(Collection, 'ownerId'),
      tagIds: this.attr([]),
      tags: this.hasManyBy(BookmarkTag, 'tagIds'),
    }
  }

  get rand() {
    return seed(this.id)()
  }

  get colorClasses() {
    return COLORS[Math.floor(this.rand * COLORS.length)]
  }

  get shape() {
    return SHAPES[Math.floor(this.rand * SHAPES.length)]
  }

  get placeholders() {
    const { shape } = this
    return {
      thumbnail: require(`@/assets/img/placeholders/${shape}-thumbnail.webp`),
      favicon: require(`@/assets/img/placeholders/${shape}-favicon.webp`),
    }
  }

  get isCollectionBookmark() {
    return !this.isPrivate
  }

  get hasFavicon() {
    return !!this.favicon
  }

  get thumbnailUrl() {
    if (!this.image) return this.placeholders.thumbnail
    return `${CONFIG.cdnUrl}/${this.image}?class=bookmarkthumbnail`
  }

  get faviconUrl() {
    if (!this.favicon) return this.placeholders.favicon
    return `${CONFIG.cdnUrl}/${this.favicon}?class=favicon`
  }

  get domain() {
    const addr = new URL(this.url)
    return addr.hostname
  }

  get brandedUrl() {
    const addr = new URL(this.url)
    addr.searchParams.set('utm_source', 'rubra')

    return addr.toString()
  }

  get hasSufficientData() {
    return !!this.title && !!this.image
  }

  // methods
  async share() {
    const { value: canShare } = await Share.canShare()

    // MVP+ add image files?
    // https://stackoverflow.com/questions/63304390/share-files-with-capacitor-share-plugin

    if (canShare) {
      await Share.share({
        dialogTitle: 'Share your bookmark', // Android only
        title: this.title,
        text: this.title,
        url: this.brandedUrl,
      })
    } else {
      await navigator.clipboard.writeText(this.brandedUrl)
      const { _vm: vm } = this.constructor.store()
      vm.$toast.info('Link copied')
    }
  }

  isOwner(userId) {
    if (this.isCollectionBookmark) {
      // if we have collection data
      if (this.owner && this.owner.ownerId === userId) return true
      return this.addedById === userId
    }

    return true
  }

  setIsSelected(value) {
    this.$update({
      isSelected: value,
    })
  }
}

export default Bookmark
