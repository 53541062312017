import store from '@/store'
import Preferences from '@/plugins/preferences'

export default function setupInterceptors(axios) {
  axios.interceptors.request.use(
    async config => {
      const accessToken = await Preferences.get('accessToken')
      if (accessToken && !config.headers.Authorization && !config.NO_AUTH) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      // eslint-disable-next-line no-param-reassign
      config.headers.appVersion = APPVERSION

      return config
    },
    error => Promise.reject(error),
  )

  axios.interceptors.response.use(
    response => {
      if (response.data.response) return response.data.response
      return response
    },
    async error => {
      if (error.response) {
        // retry with new tokens if 403
        const originalRequest = error.config
        if (error.response.status === 403 && !originalRequest.retryingWithNewTokens) {
          originalRequest.retryingWithNewTokens = true
          await store.dispatch('auth/checkOrRefreshAuth')

          delete originalRequest.headers.Authorization
          return axios(originalRequest)
        }

        const { data } = error.response
        if (data) {
          let errorData = data.error
          if (typeof errorData !== 'object') errorData = data

          return Promise.reject(errorData)
        }
      } if (error.request) {
        console.error('No response received', error.request)
      } else {
        console.error('Unexpected axios error', error.message)
      }

      return Promise.reject(error)
    },
  )
}
