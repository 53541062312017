import axios from '@/plugins/axios'

export const get = async (id, ...o) => {
  const { results, next, bookmarkCount } = await axios.get(`/collections/${id}/bookmarks`, ...o)
  return {
    results: results.map(i => ({
      ...i,
      ownerId: i.owner,
      tags: i.tags.map(t => ({ ...t, ownerId: t.owner })),
    })),
    next,
    bookmarkCount,
  }
}

export const getId = async (cId, bId, ...o) => {
  const bookmark = await axios.get(`/collections/${cId}/bookmarks/${bId}`, ...o)
  return {
    ...bookmark,
    ownerId: bookmark.owner,
    tags: bookmark.tags.map(t => ({ ...t, ownerId: t.owner })),
  }
}

export const stats = (cId, ...o) => axios.get(`/collections/${cId}/bookmarks/stats`, ...o)

export const create = (id, data, ...o) => axios.post(`/collections/${id}/bookmarks`, data, ...o)
export const edit = (cId, bId, data, ...o) => axios.patch(`/collections/${cId}/bookmarks/${bId}`, data, ...o)
export const favoriteBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/favorite`, data, ...o)
export const editTags = (cId, bId, data, ...o) => axios.patch(`/collections/${cId}/bookmarks/${bId}/tags`, data, ...o)
export const addTagsBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/add-tags`, data, ...o)
export const removeTagsBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/remove-tags`, data, ...o)
export const remove = (cId, bId, ...o) => axios.delete(`/collections/${cId}/bookmarks/${bId}`, ...o)
export const removeBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/delete`, data, ...o)
export const removeFull = (cId, bId, ...o) => axios.delete(`/collections/${cId}/bookmarks/${bId}/full`, ...o)
export const removeFullBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/delete-full`, data, ...o)
export const restore = (cId, bId, ...o) => axios.post(`/collections/${cId}/bookmarks/${bId}/restore`, ...o)
export const restoreBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/restore`, data, ...o)
export const addToCollections = (cId, bId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/${bId}/add-collections`, data, ...o)
export const addToCollectionsBulk = (cId, data, ...o) => axios.post(`/collections/${cId}/bookmarks/bulk/add-collections`, data, ...o)

export const getBulkTags = async (cId, data, ...o) => {
  const { results, next } = await axios.post(`/collections/${cId}/bookmarks/bulk/get-tags`, data, ...o)
  const tags = results.map(i => ({ ...i, ownerId: i.owner }))
  return {
    results: tags,
    next,
  }
}

export const exportBookmarks = (cId, form, ...o) => axios.post(`/collections/${cId}/bookmarks/export`, form, ...o)
export const importBookmarks = (cId, file, ...o) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/collections/${cId}/bookmarks/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, ...o })
}

export const editThumbnail = (cId, bId, file, opts) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(
    `/collections/${cId}/bookmarks/${bId}/image`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' }, ...opts },
  )
}

// tags
export const searchTags = async (cId, opts) => {
  const { results, next } = await axios.get(`/collections/${cId}/bookmarks/tags`, opts)
  return {
    results: results.map(i => ({ ...i, ownerId: i.owner })),
    next,
  }
}
export const getTagsById = async (cId, opts) => {
  const tags = await axios.get(`/collections/${cId}/bookmarks/tags/ids`, opts)
  return tags.map(i => ({ ...i, ownerId: i.owner }))
}
export const popularTags = async (cId, opts) => {
  const results = await axios.get(`/collections/${cId}/bookmarks/tags/popular`, opts)
  return results.map(i => ({ ...i, ownerId: i.owner }))
}

// comments
export const comments = async (cId, bId, opts) => {
  const { results, next } = await axios.get(`/collections/${cId}/bookmarks/${bId}/comments`, opts)
  return {
    results: results.map(i => ({
      ...i,
      userId: i.user._id,
      resourceId: bId,
      resourceType: 'bookmarks',
    })),
    next,
  }
}

export const comment = (cId, bId, data) => axios
  .post(`/collections/${cId}/bookmarks/${bId}/comments`, data)
  .then(i => ({
    ...i,
    userId: i.user,
    resourceId: bId,
    resourceType: 'bookmarks',
  }))

export const removeComment = (cId, bId, coId) => axios.delete(`/collections/${cId}/bookmarks/${bId}/comments/${coId}`)

// misc
export const prefetchUrl = (url, ...o) => axios.post('/url/prefetch', { url }, ...o)

// likes
export const likes = async (cId, bId, opts) => {
  const { results, next } = await axios.get(`/collections/${cId}/bookmarks/${bId}/likes`, opts)
  return {
    results: results.map(i => ({
      ...i,
      userId: i.user._id,
      resourceId: bId,
      resourceType: 'bookmarks',
    })),
    next,
  }
}

export const like = (cId, bId, data) => axios
  .post(`/collections/${cId}/bookmarks/${bId}/likes`, data)
  .then(i => ({
    ...i,
    userId: i.user,
    resourceId: bId,
    resourceType: 'bookmarks',
  }))

export const removeLike = (cId, bId) => axios.delete(`/collections/${cId}/bookmarks/${bId}/likes`)
