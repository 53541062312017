<template>
  <div class="bookmark-tags d-flex align-center">
    <v-chip
      v-for="tag in capped" :key="tag.id"
      color="neutral30" small
      @click.prevent.stop="$emit('tag-selected', tag)"
    >
      <span class="text-truncate" v-text="tag.name" />
    </v-chip>

    <span
      v-if="tags.length > max"
      class="font-weight-semibold text-caption"
    >
      +{{ tags.length - max }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    capped() {
      return this.tags.slice(0, this.max)
    },
  },
}
</script>

<style lang="scss" scoped>
.bookmark-tags {
  gap: 4px;
}
</style>
