import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isExtension: 'device/isExtension',
      isExtensionPopup: 'device/isExtensionPopup',
      isDesktop: 'device/isDesktop',
      isWebApp: 'device/isWeb',
      isMobile: 'device/isMobile',
      isNative: 'device/isNative',
      deviceInfo: 'device/all',
    }),
  },
}
