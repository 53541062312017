import { stateGetters, stateSetters } from '@/utils/store/store-utils'
import User from '@/store/models/user/User'
import { sha512Encode } from '@/utils/helpers'
import { AddToCollectionMethod } from '@/utils/constants'

const defaultState = {
  currentAdditionMethod: AddToCollectionMethod.COPY,
}

export default {
  name: 'user',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async edit({ rootGetters }, form) {
      const result = await this.$api.user.edit({
        ...(form.username && { username: form.username }),
        //
        ...(form.email && { email: form.email }),
        ...(form.password && { password: await sha512Encode(form.password) }),
        ...(form.mfaCode && { mfaCode: form.mfaCode }),
      })

      const userId = rootGetters['auth/userId']
      await User.update({ where: userId, data: result })

      return result
    },
    async editAvatar({ rootGetters }, file) {
      const { data } = await this.$api.user.editAvatar(file)

      const userId = rootGetters['auth/userId']
      await User.update({ where: userId, data: { avatar: data } })

      return data
    },
    async deleteAvatar({ rootGetters }) {
      await this.$api.user.deleteAvatar()

      const userId = rootGetters['auth/userId']
      await User.update({ where: userId, data: { avatar: null } })
    },
    async resendEmailConfirmation() {
      return this.$api.user.resendEmailConfirmation()
    },
    // settings
    async getSettings() {
      return this.$api.user.settingsGet()
    },
    async editSettings(_, form) {
      return this.$api.user.settingsUpdate(form)
    },
    // autocomplete
    async getEmailAutocomplete(_, search) {
      return this.$api.user.emailAutocomplete({
        params: { search },
      })
    },
  },
}
