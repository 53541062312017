<template>
  <span class="of-hidden d-flex align-center">
    <!-- FIXME v-img cannot be empty or it flickers/re-renders component weirdly -->
    <!-- probably move away from v-img entirely for anything inside virtual scroller views cuz of inters. obser. -->
    <!-- Can just style the v-responsive div with background-url and size cover -->
    <v-img
      v-if="!noIcon"
      :src="favicon" :aspect-ratio="1" width="1em" contain
      class="flex-grow-0 text-body-1 mr-2 rounded"
      :class="bookmark.hasFavicon ? '' : bookmark.colorClasses"
    />
    <span class="d-block text-truncate" v-text="domain" />
  </span>
</template>

<script>
import psl from 'psl'

export default {
  props: {
    noIcon: Boolean,
    bookmark: {
      type: Object,
      default: null,
    },
  },
  computed: {
    domain() {
      // INFO: for some domains psl returns null
      const domainParts = this.bookmark.domain.split('.')

      if (domainParts.length <= 2) {
        return this.bookmark.domain
      }

      const parsed = psl.get(this.bookmark.domain) || domainParts.slice(-2).join('.')

      return parsed.split('.').slice(-2).join('.')
    },
    favicon() {
      return this.bookmark.faviconUrl
    },
  },
}
</script>
