<template>
  <v-responsive
    class="bookmark-thumbnail pos-relative rounded" :class="colors"
    :aspect-ratio="16/9" v-bind="$attrs"
  >
    <!-- FIXME v-img cannot be empty or it flickers/re-renders component weirdly -->
    <!-- probably move away from v-img entirely for anything inside virtual scroller views cuz of inters. obser. -->
    <!-- Can just style the v-responsive div with background-url and size cover -->
    <v-img
      :src="bookmark.thumbnailUrl"
      :alt="bookmark.title"
      class="bookmark-image"
    >
      <template #placeholder>
        <v-skeleton-loader type="image" class="image-loader" />
      </template>
    </v-img>
  </v-responsive>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    bookmark: {
      type: Object,
      required: true,
    },
  },
  computed: {
    colors() {
      if (this.bookmark.image) return 'neutral25'
      return this.bookmark.colorClasses
    },
  },
}
</script>

<style lang="scss" scoped>
.image-loader {
  @include overlay;

  &:deep(.v-skeleton-loader__image) {
    background-color: var(--v-neutral50-base);
  }
}

.placeholder-svg {
  @include overlay;
}

.bookmark-image {
  @include overlay;
}
</style>
