import CollectionMember from '@/store/models/collections/CollectionMember'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Bookmark from '@/store/models/bookmarks/Bookmark'
import Collection from '@/store/models/collections/Collection'
import BulkSelectCheckbox from '@/components/BulkSelectCheckbox.vue'

export default {
  components: {
    BulkSelectCheckbox,
  },
  data() {
    return {
      isCollectionInviteDialogOpen: false,
      isBulkCollectionSelectDialogOpen: false,
      loadingBulk: {
        tags: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      bulkNumberOfBookmarks: 'bulkActions/numberOfBookmarks',
      numberOfCollections: 'bulkActions/numberOfCollections',
      isBulkActionsActive: 'bulkActions/isActive',
      showBulkActions: 'bulkActions/showActions',
      bulkSelectedBookmarks: 'bulkActions/bookmarks',
      selectedCollections: 'bulkActions/collections',
      selectedOwnedCollections: 'bulkActions/ownedCollections',
      selectedNotOwnedCollections: 'bulkActions/notOwnedCollections',
      numberOfSelectedItems: 'bulkActions/numberOfSelectedItems',
      bulkIsAllSelected: 'bulkActions/isAllSelected',
      numberOfDeletedBookmarks: 'bookmarks/deletedBookmarkCount',
      numberOfBookmarks: 'bookmarks/bookmarkCount',
      collectionId: 'bookmarks/collectionId',
      bulkTagFilters: 'bulkActions/tagFilters',
    }),
    bulkTagsNext() {
      return this.bulkTagFilters.next
    },
  },
  methods: {
    ...mapMutations({
      toggleBulkActions: 'bulkActions/toggleActions',
      closeBulkActions: 'bulkActions/closeActions',
      bulkActionClearSelected: 'bulkActions/clear',
      setIsCollectionInviteDialogOpen: 'bulkActions/setIsCollectionInviteDialogOpen',
      bulkSetIsAllSelected: 'bulkActions/setIsAllSelected',
    }),
    ...mapActions({
      toggleFavoriteBulk: 'bulkActions/toggleFavorite',
      removeSelectedBulk: 'bulkActions/delete',
      removeFullSelectedBulk: 'bulkActions/fullDelete',
      restoreSelectedBulk: 'bulkActions/restore',
      saveSelectedBulk: 'bulkActions/save',
      deleteCollectionsBulk: 'bulkActions/deleteCollections',
      removeMemberBulk: 'bulkActions/removeMember',
      getBookmarkStats: 'bookmarks/getStats',
      getTagsBulk: 'bulkActions/getTags',
    }),
    async loadTagsBulk() {
      try {
        this.loadingBulk.tags = true
        await this.getTagsBulk()
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.loadingBulk.tags = false
      }
    },
    async bulkToggleAllSelected() {
      this.bulkSetIsAllSelected(!this.bulkIsAllSelected)
      Bookmark.update({
        where: record => record.ownerId === this.collectionId,
        data: { isSelected: this.bulkIsAllSelected },
      })

      Collection.update({
        where: () => true,
        data: { isSelected: this.bulkIsAllSelected },
      })
    },
    async addBulkToFavorites() {
      try {
        const numberOfBookmarks = this.bulkNumberOfBookmarks
        await this.toggleFavoriteBulk({
          isFavorite: true,
        })
        this.$toast.success(this.$tc('bulk.toast.added_to_favorites', numberOfBookmarks))
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },
    async removeBulkToFavorites() {
      try {
        const numberOfBookmarks = this.bulkNumberOfBookmarks
        await this.toggleFavoriteBulk({
          isFavorite: false,
        })
        this.$toast.success(this.$tc('bulk.toast.removed_from_favorites', numberOfBookmarks))
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },
    async deleteBulkSelected() {
      try {
        const numberOfBookmarks = this.bulkNumberOfBookmarks
        const confirmDialog = await this.$modal.open('confirm', {
          payload: {
            title: this.$tc('bulk.dialog.delete.title', numberOfBookmarks),
            text: this.$tc('bulk.dialog.delete.text', numberOfBookmarks),
            confirmBtnText: this.$t('common.delete'),
          },
        })

        const confirmed = await confirmDialog.result
        if (!confirmed) return

        await this.removeSelectedBulk()
        await this.getBookmarkStats()
        this.$toast.success(this.$tc('bulk.toast.deleted', numberOfBookmarks))
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },
    async deleteFullBulkSelected() {
      try {
        const numberOfBookmarks = this.bulkNumberOfBookmarks

        const confirmDialog = await this.$modal.open('confirm', {
          payload: {
            title: this.$tc('bulk.dialog.delete_permanently.title', numberOfBookmarks),
            text: this.$tc('bulk.dialog.delete_permanently.text', numberOfBookmarks),
            confirmBtnText: this.$tc('common.delete'),
          },
        })

        const confirmed = await confirmDialog.result
        if (!confirmed) return

        await this.removeFullSelectedBulk()
        await this.getBookmarkStats()
        this.$toast.success(this.$tc('bulk.toast.deleted_permanently', numberOfBookmarks))
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },
    async restoreBulkSelected() {
      try {
        const numberOfBookmarks = this.bulkNumberOfBookmarks
        await this.restoreSelectedBulk()
        await this.getBookmarkStats()
        this.$toast.success(this.$tc('bulk.toast.restored', numberOfBookmarks))
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },
    async addBulkToMyBookmarks(ignoreExists = false) {
      const numberOfBookmarks = this.bulkNumberOfBookmarks

      try {
        await this.saveSelectedBulk({ ignoreExists })
        this.$toast.success(this.$tc('bulk.toast.saved', numberOfBookmarks))
      } catch (error) {
        if (error.message === 'BOOKMARK_EXISTS' && !ignoreExists) {
          const confirmDialog = await this.$modal.open('confirm', {
            payload: {
              title: this.$t('bulk.dialog.duplicate_bookmark.title'),
              text: this.$t('bulk.dialog.duplicate_bookmark.collection.text'),
              confirmBtnText: this.$t('cta.add_anyway'),
            },
          })
          const confirmed = await confirmDialog.result
          // eslint-disable-next-line no-throw-literal
          if (!confirmed) throw false

          await this.saveSelectedBulk({ ignoreExists: true })
          await this.getBookmarkStats()
          this.$toast.success(this.$tc('bulk.toast.saved', numberOfBookmarks))
        }
      } finally {
        this.toggleBulkActions()
      }
    },
    addBulkToCollections() {
      this.$router.push('/bookmarks/bulk/add-collections')
    },
    bulkAddTags(collectionId = '') {
      if (collectionId) {
        this.$router.push({
          path: `/collections/${collectionId}/bulk/add-tags`,
          query: this.$route.query,
        })
      } else {
        this.$router.push({
          path: '/bookmarks/bulk/add-tags',
          query: this.$route.query,
        })
      }
    },
    bulkRemoveTags(collectionId = '') {
      if (collectionId) {
        this.$router.push({
          path: `/collections/${collectionId}/bulk/remove-tags`,
          query: this.$route.query,
        })
      } else {
        this.$router.push({
          path: '/bookmarks/bulk/remove-tags',
          query: this.$route.query,
        })
      }
    },
    bulkToggleBookmarkSelection(bookmark) {
      bookmark.setIsSelected(!bookmark.isSelected)
      const totalBookmarks = this.numberOfBookmarks

      if (this.bulkIsAllSelected && bookmark.isSelected) {
        this.bulkSetIsAllSelected(false)
      }

      if (this.bulkSelectedBookmarks.length === totalBookmarks) {
        this.bulkSetIsAllSelected(true)
      }
    },
    toggleCollectionBulk(collection) {
      collection.setIsSelected(!collection.isSelected)
      // TODO stats get total collections
      const totalCollections = Collection.query().count()

      if (this.bulkIsAllSelected && collection.isSelected) {
        this.bulkSetIsAllSelected(false)
      }

      if (this.selectedCollections.length === totalCollections) {
        this.bulkSetIsAllSelected(true)
      }
    },
    async bulkDeleteOrLeaveCollections() {
      try {
        const notOwnedCollectionIds = this.selectedNotOwnedCollections.map(i => i.id)

        const members = CollectionMember.query()
          .where('userId', this.userId)
          .where(collectionMember => notOwnedCollectionIds.includes(collectionMember.collectionId))
          .get()

        const memberIds = members.map(i => i.id)

        const confirmDialog = await this.$modal.open('confirm', {
          payload: {
            title: this.$tc('bulk.dialog.delete_or_leave_collection.title', this.selectedCollections.length),
            text: this.$tc('bulk.dialog.delete_or_leave_collection.text', this.selectedCollections.length),
            confirmBtnText: this.$t('common.okay'),
          },
        })

        const confirmed = await confirmDialog.result
        if (!confirmed) return

        const jobs = []

        if (this.selectedOwnedCollections.length > 0) {
          jobs.push(this.deleteCollectionsBulk({
            collections: this.selectedOwnedCollections,
          }))
        }

        if (this.selectedNotOwnedCollections.length > 0) {
          jobs.push(this.removeMemberBulk({
            collections: this.selectedNotOwnedCollections,
            memberIds,
          }))
        }

        await Promise.all(jobs)
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.toggleBulkActions()
      }
    },

    async bulkInviteContributors() {
      this.isCollectionInviteDialogOpen = true
    },

    async bulkEditInviteLink() {
      this.$router.push('/collections/bulk/edit-share-link')
    },
  },

}
