import store from '@/store'
import { awaitGetter } from '@/utils/store/store-utils'
import BookmarksView from '@/views/Bookmarks/All.vue'

export default [
  {
    path: '/bookmarks',
    components: {
      default: BookmarksView,
      sidebar: () => import(/* webpackChunkName: "bm-sidebar" */ '@/views/Bookmarks/components/BookmarksSidebar.vue'),
      appbar: () => import(/* webpackChunkName: "bm-appbar" */ '@/views/Bookmarks/components/BookmarkAppBar.vue'),
      footer: () => import(/* webpackChunkName: "footer" */ '@/components/AppFooterExpandable.vue'),
    },
    beforeEnter: async (to, from, next) => {
      await awaitGetter(store, 'bookmarks/collectionId')
      next()
    },
    meta: {
      sidebarVisibility() {
        return true
      },
      appbarVisibility: true,
      requiresAuth: true,
      transition: 't-fade',
      layoutTransition: 't-expand',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/deleted',
    components: {
      default: () => import(/* webpackChunkName: "bm-deleted" */ '@/views/Bookmarks/Deleted.vue'),
      appbar: () => import(/* webpackChunkName: "bm-appbar" */ '@/views/Bookmarks/components/BookmarkAppBar.vue'),
      footer: () => import(/* webpackChunkName: "footer" */ '@/components/AppFooterExpandable.vue'),
    },
    beforeEnter: async (to, from, next) => {
      await awaitGetter(store, 'bookmarks/collectionId')
      next()
    },
    meta: {
      appbarVisibility: true,
      requiresAuth: true,
      transition: 't-fade',
      layoutTransition: 't-expand',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/add',
    component: () => import(/* webpackChunkName: "bm-add" */ '@/views/Bookmarks/AddBookmark.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/:bookmarkId/edit',
    component: () => import(/* webpackChunkName: "bm-edit" */ '@/views/Bookmarks/Edit.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/bulk/add-tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/BulkEditTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/bulk/remove-tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/BulkRemoveTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/:bookmarkId/tags',
    component: () => import(/* webpackChunkName: "bm-tags-edit" */ '@/views/Bookmarks/EditTags.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/bulk/add-collections',
    component: () => import(/* webpackChunkName: "bm-add-col" */ '@/views/Bookmarks/BulkAddToCollections.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/:id/add-collections',
    component: () => import(/* webpackChunkName: "bm-add-col" */ '@/views/Bookmarks/AddToCollections.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/bookmarks/:id/notes',
    component: () => import(/* webpackChunkName: "bm-notes" */ '@/views/Bookmarks/Notes.vue'),
    meta: {
      requiresAuth: true,
      layout: 'focus',
      transition: 't-fade',
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
]
