import { sha512Encode } from '@/utils/helpers'
import { stateGetters, stateSetters } from '@/utils/store/store-utils'

const defaultState = {
  setupForm: null,
}

export default {
  name: 'auth/mfa',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async setup({ commit }, form) {
      const setupForm = await this.$api.auth.mfaSetup({
        password: await sha512Encode(form.password),
      })

      commit('setSetupForm', setupForm)
      return setupForm
    },
    async confirm({ commit }, form) {
      await this.$api.auth.mfaConfirm(form)
      commit('auth/setUserProp', ['mfaActive', true], { root: true })
    },
    async remove({ commit }, form) {
      await this.$api.auth.mfaRemove({
        ...form,
        password: await sha512Encode(form.password),
      })
      commit('auth/setUserProp', ['mfaActive', false], { root: true })
    },
    async revoke(_, form) {
      return this.$api.auth.mfaRevoke({
        ...form,
        password: await sha512Encode(form.password),
      })
    },
  },
}
